import {jsonFetch} from "./JsonFetch";

let Oauth2 = {

    /**
     *
     */
    loginChallenge: async (challenge) => {
        let url = '/oauth2/challenge/skip';

        const response = await jsonFetch('POST', url, {
            challenge
        });

        if (response.ok) {
            return response.json();
        }

        throw Error(response.statusText);
    },

    /**
     *
     */
    consentChallenge: async (challenge) => {
        let url = '/oauth2/challenge/consent';

        const response = await jsonFetch('POST', url, {
            challenge
        });
        if (response.ok) {
            return response.json();
        }
        throw Error(response.statusText);
    },

}

export {Oauth2};
