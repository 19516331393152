import './App.css';
import './Responsive.css';
import 'react-responsive-modal/styles.css';

import React, {useEffect, useState} from "react"
import {Configuration, V0alpha2Api} from "@ory/client"

import {
    Avatar,
    Badge,
    Button,
    Col,
    Divider,
    Drawer,
    Image,
    Layout,
    List,
    Menu,
    Row,
    Space,
    Tooltip,
    Typography,
    ConfigProvider
} from "antd";
import {
    BellOutlined,
    CommentOutlined,
    DatabaseOutlined,
    HomeOutlined,
    MailOutlined,
    PhoneOutlined,
    PlusOutlined,
    RobotOutlined,
    SettingOutlined,
    ShareAltOutlined,
    ToolOutlined,
    UnorderedListOutlined,
    CreditCardOutlined, QuestionCircleOutlined
} from '@ant-design/icons';
const { Text } = Typography;
import camille from './assets/Camille.png';

import Sider from "antd/es/layout/Sider";
import logo from './assets/logo_blanc.png';
import {BrowserRouter as Router, Link, Route, Routes, useNavigate} from "react-router-dom";

import Home from "./pages/Home";
import Contacts from "./pages/Contacts";
import Documents from "./pages/Documents";
import SharedDocuments from "./pages/SharedDocuments";
import Login from "./pages/login/Login";

import ory from "./helpers/ory";
import ContactModal from "./components/ContactModal";
import ListGenerator from "./pages/ListGenerator";
import {AutoTask} from "./api/AutoTask";
import {Notification} from "./api/Notification";
import {Profile} from "./api/Profile";
import {Config} from "./config";
import TagsSettings from "./pages/TagsSettings";
import DocumentCategoriesSettingsPage from "./pages/DocumentCategoriesSettings";
import EmailVerification from "./pages/login/EmailVerification";
import LoginRecoveryRequest from "./pages/login/LoginRecoveryRequest";
import LoginRecovery from "./pages/login/LoginRecovery";
import ProfilePage from "./pages/Profile";
import SharedUrlsPage from "./pages/SharedUrls";
import Modal from "react-responsive-modal";
import Register from "./pages/login/Register";
import EventCal from "./pages/EventCal";
import NoDataContact from "./pages/NoDataContact";
import OAuthLogin from "./pages/oauth2/Login";
import OAuthConsent from "./pages/oauth2/Consent";
import BillingPage from "./pages/Billing";
import {Quota} from "./api/Quota";
import {Bulk} from "./api/Bulk";

const {Header, Footer, Content} = Layout;


class CannyLoader {
    get Canny() {
        return (window).Canny;
    }

    async load() {
        if (this.Canny) {
            return this.Canny;
        }

        const script = document.createElement("script");

        script.type = "text/javascript";
        script.async = true;
        script.src = "https://canny.io/sdk.js";

        return new Promise((resolve, reject) => {
            script.onload = () => {
                resolve((window).Canny);
            };

            script.onerror = (err) => {
                reject(err);
            };

            document.head.append(script);
        });
    }
}


const BoardToken = 'db327a2c-30eb-9355-da14-174cf8313fa3';

const Feedback = (props) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let loader = new CannyLoader();
        if (props.profile) {
            loader.load().then(() => {
                let profile = {...props.profile}
                window.Canny('render', {
                    boardToken: BoardToken,
                    ssoToken: profile.sso_token, // See step 3
                    onLoadCallback: () => setLoading(false),
                });
            })
        }
    }, []);

    let message = null;
    if (loading === true) {
        message = 'Chargement ...';
    }

    return (
        <>
            {message}
            <div data-canny/>
        </>
    );
}


/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function AppLayout(props) {

    const [contactModalVisible, setContactModalVisible] = useState(false);
    const [visibleCoachDrawer, setVisibleCoachDrawer] = useState(false);
    const [visibleAutoTaskDrawer, setVisibleAutoTaskDrawer] = useState(false);
    const [visibleProfileDrawer, setVisibleProfileDrawer] = useState(false);
    const [visibleFeedbackModal, setVisibleFeedbackModal] = useState(false);
    const [visibleNotificationsDrawer, setVisibleNotificationsDrawer] = useState(false);
    const [countAutoTask, setCountAutoTask] = useState(0);
    const [countNotifications, setCountNotifications] = useState(0);
    const [autoTasks, setAutoTasks] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const [profile, setProfile] = useState(null);
    const [quota, setQuota] = useState({'max': 0, 'current': 0})

    useEffect(() => {
        loadProfile();

        Quota.getAvailableQuota().then((quota) => {
            setQuota(quota);
        });

        const interval = setInterval(() => {
            Bulk.getAll().then((data) => {
                setCountNotifications(data.notifications.length)
                setNotifications(data.notifications)

                setCountAutoTask(data.auto_tasks.length)
                setAutoTasks(data.auto_tasks)

                setQuota(data.quota);
            })
        }, 3000);

        return () => clearInterval(interval);
    }, []);

    let navigate = useNavigate();

    let loadProfile = () => {
        Profile.me().then(profile => {
            setProfile(profile);
        });
    }

    let onCloseContactModalHandler = () => {
        setContactModalVisible(false);
    }

    let onSaveContactModalHandler = () => {
        setContactModalVisible(false);
    }

    let onClickShowContactModalHandler = () => {
        setContactModalVisible(true);
    }

    let onCloseCoachDrawerHandler = () => {
        setVisibleCoachDrawer(false)
    }

    let onClickVisibleCoachDrawerHandler = () => {
        setVisibleCoachDrawer(true)
    }

    let onClickVisibleAutoTaskDrawerHandler = () => {
        setVisibleAutoTaskDrawer(true)
    }

    let onCloseAutoTaskDrawerHandler = () => {
        setVisibleAutoTaskDrawer(false)
    }

    let onClickVisibleProfileDrawerHandler = () => {
        setVisibleProfileDrawer(true);
    }

    let onCloseProfileDrawerHandler = () => {
        setVisibleProfileDrawer(false);
    }

    let onClickVisibleNotificationsDrawerHandler = () => {
        setVisibleNotificationsDrawer(true)
    }

    let onCloseNotificationsDrawerHandler = () => {
        setVisibleNotificationsDrawer(false);
    }

    let onClickShowFeedbackModalHandler = () => {
        setVisibleFeedbackModal(true);
    }

    let onClickCloseFeedbackModalHandler = () => {
        setVisibleFeedbackModal(false);
    }

    let onClickLogoutHandler = () => {

        ory.createSelfServiceLogoutFlowUrlForBrowsers().then((data) => {
            fetch(data.data.logout_url, {
                credentials: 'include',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                })
            }).then(() => {
                navigate('/login');
            })
        })
    }

    /**
     *
     * @param notification
     * @returns {JSX.Element}
     */
    let formatNotificationItem = (notification) => {
        if (notification['name'] == 'csv_file_import_success') {
            return (
                <List.Item>
                    <List.Item.Meta
                        title={notification.title}
                        description={notification.description}
                    />
                </List.Item>
            )
        } else if (notification['name'] == 'csv_file_generator_success') {
            return (
                <List.Item actions={[<a key="list-loadmore-edit" onClick={() => {
                    window.open(Config.apiUrl + "/csv/file/" + notification.meta.export_name + "/download", "_blank")
                }}>télécharger</a>]}>
                    <List.Item.Meta
                        title={notification.title}
                        description={notification.description}
                    />
                </List.Item>
            )
        }
    }

    /**
     *
     * @param task
     * @returns {JSX.Element}
     */
    let formatTaskItem = (task) => {
        if (task['name'] == 'csv_file_import') {
            return (
                <List.Item>
                    <List.Item.Meta
                        title={"Import du fichier CSV"}
                        description="Vous pourrez voir les contacts importés dans quelques instants"
                    />
                </List.Item>
            )
        } else if (task['name'] == 'csv_file_generator') {
            return (
                <List.Item>
                    <List.Item.Meta
                        title={"Génération de votre fichier CSV"}
                        description="Vous pourrez le télécharger dans quelques instants"
                    />
                </List.Item>
            )
        }
    }

    const getItems = () => {
        return [{
            'key': '1',
            'icon': <HomeOutlined/>,
            'label': <Link to={'/'}>Accueil</Link>
        }, {
            'key': '2',
            'icon': <UnorderedListOutlined/>,
            'label': <Link to={'/contacts'}>Contacts</Link>,
        }, {
            'key': '3',
            'icon': <DatabaseOutlined/>,
            'label': <Link to={'/documents'}>Documents</Link>,
        }, {
            'key': '4',
            'icon': <ShareAltOutlined/>,
            'label': "Suivis",
            'children': [{
                'key': '41',
                'label': <Link to={'/shareddocuments'}>Documents</Link>,
            }, {
                'key': '42',
                'label': <Link to={'/sharedurls'}>Urls</Link>,
            }]
        }, {
            'key': 'sub4',
            'icon': <ToolOutlined/>,
            'label': 'Outils',
            'children': [{
                'key': '10',
                'label': <Link to={'/list_generator'}>Suivi de liste</Link>,
            }]
        }, {
            'key': 'sub5',
            'icon': <SettingOutlined/>,
            'label': 'Paramètres',
            'children': [{
                'key': '13',
                'label': <Link to={'/tags'}>Tags personnalisés</Link>,
            }, {
                'key': '14',
                'label': <Link to={'/document_categories'}>Catégories de document</Link>,
            }]
        }, {
            'key': 'sub6',
            'icon': <CreditCardOutlined/>,
            'label': <Link to={'/billing'}>Plans / Facturation</Link>,
        }];
    }

    let items = getItems();


    return (
        <div>
            <Modal open={visibleFeedbackModal} showCloseIcon={true} onClose={onClickCloseFeedbackModalHandler} center
                   classNames={{
                       overlay: 'customOverlay',
                       modal: 'customModal',
                   }}>
                <div style={{"height": "100%"}}>
                    <div style={{"height": "100%"}}>
                        <Feedback profile={profile}/>
                    </div>
                </div>
            </Modal>
            <Layout style={{minHeight: '100vh'}}>
                <Sider width={250}
                       theme="dark"
                       breakpoint="lg"
                       collapsedWidth="0"
                       onBreakpoint={broken => {
                       }}
                       onCollapse={(collapsed, type) => {
                       }}
                >
                    <div className="logo" style={{textAlign: "center", transition: "all 10ms ease"}}>
                        <img alt={'logo'} src={logo}/>
                    </div>
                    <Menu theme="dark" mode="inline" defaultSelectedKeys={['4']} items={items}/>
                    <Row style={{marginTop: "30px"}} onClick={onClickShowFeedbackModalHandler}>
                        <Col span={24} style={{textAlign: "center"}}>
                            <Button shape="round" icon={<CommentOutlined style={{color: "#F6620A"}}/>}>
                                Suggestions
                            </Button>
                        </Col>
                    </Row>
                </Sider>
                <Layout>

                    <Header className="site-layout-sub-header-background">
                        <Row>
                            <Col span={12}></Col>
                            <Col span={12} style={{
                                textAlign: "right"
                            }}>
                                <Space>

                                    <div style={{
                                        'display': 'inline-block',
                                        'verticalAlign': 'middle'
                                    }}>
                                        <div style={{
                                            'display': 'grid',
                                            'gridTemplateRows': '1fr'
                                        }}>
                                            <div><Badge style={{top: '-3px'}} count={quota.current} showZero={true} overflowCount={quota.current} color='#1677ff'/> <Tooltip title="Nombre de liens suivis que vous pouvez encore créer, selon votre plan d'abonnement."><QuestionCircleOutlined /></Tooltip></div>
                                        </div>
                                    </div>

                                    <Divider type="vertical"/>

                                    <Tooltip title="Nouveau contact">
                                        <Button shape="circle" icon={<PlusOutlined/>} size="large"
                                                onClick={onClickShowContactModalHandler}/>
                                    </Tooltip>

                                    <Divider type="vertical"/>


                                    <Badge className="header-big-button bell-button" count={countAutoTask}
                                           offset={[-5, 5]}>
                                        <Button shape="circle" icon={<RobotOutlined/>} size="large"
                                                onClick={onClickVisibleAutoTaskDrawerHandler}/>
                                    </Badge>

                                    <Badge className="header-big-button bell-button" count={countNotifications}
                                           offset={[-5, 5]}>
                                        <Button shape="circle" icon={<BellOutlined/>} size="large"
                                                onClick={onClickVisibleNotificationsDrawerHandler}/>
                                    </Badge>


                                    <Divider type="vertical"/>

                                    <Avatar onClick={onClickVisibleCoachDrawerHandler} style={{
                                        margin: "0 0 5px 0",
                                        cursor: "pointer"
                                    }} size={45} src={camille}/>
                                    <Divider type="vertical"/>

                                    <div>
                                        <Avatar onClick={onClickVisibleProfileDrawerHandler} style={{
                                            color: '#f56a00',
                                            backgroundColor: '#b1e4ce',
                                            margin: "0 0 5px 0",
                                            cursor: "pointer"
                                        }}
                                                size={40}>{profile?.firstname?.substring(0, 1).toUpperCase()}{profile?.lastname?.substring(0, 1).toUpperCase()}</Avatar>
                                    </div>
                                </Space>
                            </Col>
                        </Row>
                    </Header>
                    <Content style={{margin: '24px 16px 0', minHeight: 360}}>
                        {props.children}
                    </Content>
                    <Footer style={{textAlign: 'center'}}>Skani</Footer>
                    <ContactModal open={contactModalVisible} onClose={onCloseContactModalHandler}
                                  onSave={onSaveContactModalHandler}/>
                    <Drawer title="Votre coach" placement="right" onClose={onCloseCoachDrawerHandler}
                            visible={visibleCoachDrawer}>
                        <Row>
                            <Col span={24} style={{textAlign: "center"}}>
                                <Image preview={false} style={{
                                    borderRadius: "50%",
                                    width: "150px",
                                    height: "150px",
                                }} src={camille}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} style={{textAlign: "center"}}>
                                <p style={{
                                    fontSize: "16px",
                                    margin: "18px 0 40px 0"
                                }}> <Text italic>Bonjour ! Je suis Camille du support Skani ! Posez-moi votre question, j’y répondrai au plus vite</Text> 😊</p>
                                <p><MailOutlined style={{
                                    fontSize: "32px"
                                }}/></p>
                                <p><a href={"mailto:camille@skani.xyz"}>camille@skani.xyz</a></p>
                            </Col>
                        </Row>
                    </Drawer>

                    <Drawer title="Votre profil" placement="right" onClose={onCloseProfileDrawerHandler}
                            visible={visibleProfileDrawer}>
                        <Row>
                            <Col span={24} style={{textAlign: "center"}}>
                                <Avatar size={150}
                                        style={{fontSize: "50px"}}>{profile?.firstname?.substring(0, 1).toUpperCase()}{profile?.lastname?.substring(0, 1).toUpperCase()}</Avatar>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} style={{textAlign: "center"}}>
                                <p style={{
                                    fontSize: "26px",
                                    margin: "18px 0 0 0"
                                }}>{profile?.firstname} {profile?.lastname}</p>
                                <p style={{
                                    fontSize: "16px",
                                    margin: "0 0 40px 0"
                                }}></p>
                                <p>
                                    <Link to={'/profile'} onClick={() => setVisibleProfileDrawer(false)}>
                                        <Button shape="round" size={"large"}>
                                            Modifier
                                        </Button>
                                    </Link>
                                </p>

                                <p>
                                    <Button onClick={onClickLogoutHandler} shape="round" size={"large"}>
                                        Se déconnecter
                                    </Button>
                                </p>
                            </Col>
                        </Row>
                    </Drawer>

                    <Drawer title="Tâches automatiques en cours" placement="right"
                            onClose={onCloseAutoTaskDrawerHandler}
                            visible={visibleAutoTaskDrawer}>
                        <List
                            itemLayout="horizontal"
                            dataSource={autoTasks}
                            renderItem={task => formatTaskItem(task)}
                        />
                    </Drawer>


                    <Drawer title="Notifications" placement="right" onClose={onCloseNotificationsDrawerHandler}
                            visible={visibleNotificationsDrawer}>
                        <List
                            itemLayout="horizontal"
                            dataSource={notifications}
                            renderItem={notification => formatNotificationItem(notification)}
                        />
                    </Drawer>

                </Layout>
            </Layout>
        </div>
    )
}

function SplashScreen() {
    return (<>Chargement ...</>)
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({children, ...rest}) {
    const [loading, setLoading] = useState(true);
    const [session, setSession] = useState()
    const [logoutUrl, setLogoutUrl] = useState()

    let navigate = useNavigate();

    useEffect(() => {
        ory
            .toSession()
            .then(({data}) => {
                setSession(data)
                ory.createSelfServiceLogoutFlowUrlForBrowsers().then(({data}) => {
                    // Get also the logout url
                    setLogoutUrl(data.logout_url);
                    setLoading(false);
                })
            })
            .catch(() => {
                navigate('/login')
            })
    }, [])

    if (loading) {
        return (<SplashScreen/>)
    }

    return !rest.withoutLayout ? (
        <AppLayout>{children}</AppLayout>
    ) : children;
}

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const App = () => {

    // Get your Ory url from .env
    // Or localhost for local development
    const basePath = process.env.REACT_APP_ORY_URL || "http://localhost:4000"
    const ory = new V0alpha2Api(
        new Configuration({
            basePath,
            baseOptions: {
                withCredentials: true,
            },
        }),
    )

    return (
        <Router>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: '#fa541c',
                    },
                }}
            >
                <div className="App">
                    <Routes>
                        <Route path="/"
                            element={
                                <PrivateRoute>
                                    <Home/>
                                </PrivateRoute>
                            }
                        />
                        <Route path="/login"
                            element={
                                <Login/>
                            }
                        />
                        <Route path="/oauth2/login"
                            element={
                                <OAuthLogin/>
                            }
                        />
                        <Route path="/oauth2/consent"
                            element={
                                <OAuthConsent/>
                            }
                        />
                        <Route path="/register"
                            element={
                                <Register/>
                            }
                        />
                        <Route path="/contacts"
                            element={
                                <PrivateRoute>
                                    <Contacts/>
                                </PrivateRoute>
                            }
                        />
                        <Route path="/contacts/:id"
                            element={
                                <PrivateRoute>
                                    <Contacts/>
                                </PrivateRoute>
                            }
                        />
                        <Route path="/documents"
                            element={
                                <PrivateRoute>
                                    <Documents/>
                                </PrivateRoute>
                            }
                        />
                        <Route path="/shareddocuments"
                            element={
                                <PrivateRoute>
                                    <SharedDocuments/>
                                </PrivateRoute>
                            }
                        />
                        <Route path="/sharedurls"
                            element={
                                <PrivateRoute>
                                    <SharedUrlsPage/>
                                </PrivateRoute>
                            }
                        />
                        <Route path="/list_generator"
                            element={
                                <PrivateRoute>
                                    <ListGenerator/>
                                </PrivateRoute>
                            }
                        />
                        <Route path="/tags"
                            element={
                                <PrivateRoute>
                                    <TagsSettings/>
                                </PrivateRoute>
                            }
                        />
                        <Route path="/document_categories"
                            element={
                                <PrivateRoute>
                                    <DocumentCategoriesSettingsPage/>
                                </PrivateRoute>
                            }
                        />
                        <Route path="/login/verification"
                            element={
                                <EmailVerification/>
                            }
                        />
                        <Route path="/login/recovery/request"
                            element={
                                <LoginRecoveryRequest/>
                            }
                        />
                        <Route path="/login/recovery"
                            element={
                                <LoginRecovery/>
                            }
                        />
                        <Route path="/eventscal"
                            element={
                                <EventCal/>
                            }
                        />
                        <Route path="/contacts/:id/nodatacontact"
                            element={
                                <NoDataContact/>
                            }
                        />
                        <Route path="/profile"
                            element={
                                <PrivateRoute>
                                    <ProfilePage/>
                                </PrivateRoute>
                            }
                        />
                        <Route path="/billing"
                               element={
                                   <PrivateRoute>
                                       <BillingPage/>
                                   </PrivateRoute>
                               }
                        />
                    </Routes>
                </div>
            </ConfigProvider>

        </Router>
    )
};

export default App;

