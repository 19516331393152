import {Alert, Button, Card, DatePicker, Divider, Form, Image, Input, Space, TimePicker} from "antd";
import React, {useEffect, useState} from "react"
import {AppleOutlined, FileOutlined, GoogleOutlined, WindowsOutlined} from "@ant-design/icons";
import ical from "ical-generator";
import {Link, useParams, useSearchParams} from "react-router-dom";
import logo from '../assets/Skani.png';
import moment from "moment";
import {Config} from "../config";
import useForm from "antd/es/form/hooks/useForm";

const {TextArea} = Input;

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
function NoDataContact() {

    const [shortLink, setShortLink] = useState(null);
    const [searchParams] = useSearchParams();

    let {id} = useParams();

    let title = null;
    let description = null;

    if (searchParams.get('type') == 'nophone') {
        title = 'Aucun téléphone trouvé';
        description = (<p>On dirait que votre contact n'a pas encore de téléphone.  <br/><br/>Pensez à remplir sa fiche dès que vous aurez cette information.</p>)
    } else if (searchParams.get('type') == 'nomail') {
        title = 'Aucune adresse email trouvée';
        description = (<p>On dirait que votre contact n'a pas encore d'adresse email.  <br/><br/>Pensez à remplir sa fiche dès que vous aurez cette information.</p>)
    }

    return (
        <>
            <div className={"event-calendar-box"}>
                <Image src={logo} alt="Logo" height={50} width={'auto'} preview={false} style={{margin: "0 0 50px 0"}}/>
                <Alert
                    message={title}
                    description={description}
                    type="info"
                    showIcon
                />
                <div style={{
                    textAlign: "center",
                    marginTop: "35px"
                }}>
                    <Link to={"/contacts/" + id}><Button size={'large'}>Voir la fiche</Button></Link>
                </div>
            </div>
        </>
    )
}

export default NoDataContact;

