import {Badge, Button, Card, Col, Descriptions, Divider, Modal, Result, Row, Spin,} from "antd";
import React, {useEffect, useState} from "react"
import {CheckOutlined, LoadingOutlined} from "@ant-design/icons";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {PageHeader} from "@ant-design/pro-components";
import {Billing} from "../api/Billing";
import moment from "moment";

function BigButton(props) {

    const antIcon = <LoadingOutlined style={{fontSize: 24}} spin/>;

    if (props.subscription && props.offer.stripePriceId === props.subscription.stripe_price_id) {
        return (
            <div
                style={{
                    padding: "25px",
                    borderRadius: "5px",
                    fontSize: "18px",
                    lineHeight: "29px",
                    color: "green",
                    border: "2px solid green",
                }}>
                Plan actuel <CheckOutlined/>
            </div>
        )
    }

    return (
        <div
            style={{
                padding: "25px",
                borderRadius: "5px",
                fontSize: "18px",
                lineHeight: "29px",
                backgroundColor: "#fa541c",
                color: "white",
                cursor: "pointer"
            }} onClick={() => {
            props.setSelectedStripePriceId(props.offer.stripePriceId);
            props.setPlanInProgress(props.offer.stripePriceId);

            if (props.offer.price === 0) {
                props.setIsModalUnsubscribeOpen(true);
            } else {
                props.createCheckoutSession(props.offer.stripePriceId);
            }
        }}>{(props.planInProgress == props.offer.stripePriceId) ?
            <Spin style={{color: "white"}} indicator={antIcon}/> : "Mettre à niveau"}
        </div>
    )
}


/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
function BillingPage() {

    const [searchParams, setSearchParams] = useSearchParams()

    const [isModalModifyOpen, setIsModalModifyOpen] = useState(false);
    const [isModalUnsubscribeOpen, setIsModalUnsubscribeOpen] = useState(false);
    const [invoiceForModification, setInvoiceForModification] = useState(null);
    const [selectedStripePriceId, setSelectedStripePriceId] = useState(null);
    const [subscription, setSubscription] = useState(null);
    const [planInProgress, setPlanInProgress] = useState(false);
    const [partToShow, setPartToShow] = useState('plans');

    useEffect(() => {
        Billing.getCurrentSubscription().then((subscription) => {
            setSubscription(subscription);
        })

        if (searchParams.get('success') == 'true') {
            setPartToShow('successfully_purchased');
        }
    }, []);

    const offers = [{
        title: "FREEMIUM",
        subtitle: "10 liens suivis par mois",
        price: 0,
        priceMode: "à vie",
        description: "Besoin de tester le suivi documentaire ? Ou vous n'envoyez que très peu de fichier attachés. Découvrez la puissance de la solution SKANI. ! Aucune carte de crédit nécessaire. Accès instantané à SKANI.",
        stripePriceId: null,
        badgeText: null
    }, {
        title: "PLAN SALES",
        subtitle: "50 liens suivis par mois",
        price: 9.90,
        priceMode: "mois",
        description: "Un plan fait pour les commerciaux et les indépendants. Suivez vos documents, propositions commerciales et devis. Détectez facilement les bonnes opportunités. Lancez vos premières campagnes.",
        stripePriceId: "price_1Mu1VEJo4u692CFcXJsvVboG",
        badgeText: null
    }, {
        title: "PLAN SMARKETING",
        subtitle: "1 000 liens suivis par mois",
        price: 24.90,
        priceMode: "mois",
        description: "Un plan fait pour les pros travaillant sur de petites campagnes. Alignez vos campagnes emailing avec vos relances commerciales et ciblez facilement les opportunités les plus chaudes.",
        stripePriceId: "price_1Mu0ZtJo4u692CFcu11e1Oa4",
        badgeText: 'Le plus populaire'
    }, {
        title: "PLAN MASS SMARKETING",
        subtitle: "10 000 liens suivis par mois",
        price: 42.90,
        priceMode: "mois",
        description: "Vous êtes une agence ou un département marketing, ce plan est fait pour vous. Vous gérez des campagnes à gros volumes et souhaitez suivre vos documents sur la totalité du parcours d'achat.",
        stripePriceId: "price_1Mu0aTJo4u692CFchCBezBKs",
        badgeText: 0
    }]

    let createCheckoutSession = (stripePriceId) => {
        Billing.stripeCreateCheckoutSession(stripePriceId).then((resp) => {
            if (resp.action && resp.action == 'modify') {
                setIsModalModifyOpen(true);
                setInvoiceForModification(resp.invoice);
            } else if (resp.checkout_session_url) {
                document.location.href = resp.checkout_session_url;
            }
        })
    }

    let myInvoices = () => {
        Billing.stripeCreatePortalSession().then((resp) => {
            document.location.href = resp.portal_session;
        })
    }

    let handleOk = () => {
        Billing.stripeAcceptModification(selectedStripePriceId).then((resp) => {
            setIsModalModifyOpen(false);
            setPlanInProgress(false);
            setPartToShow('successfully_purchased');
        })
    }
    let handleCancel = () => {
        setPlanInProgress(false);
        setIsModalModifyOpen(false);
    }

    let handleUnsubscribeOk = () => {
        Billing.stripeCancelSubscription().then((resp) => {
            setIsModalUnsubscribeOpen(false);
        })
    }
    let handleUnsubscribeCancel = () => {
        setPlanInProgress(false);
        setIsModalUnsubscribeOpen(false);
    }

    let onClickSuccessfullyPurchaseHandler = () => {
        showPlans();
    }

    let showPlans = () => {
        Billing.getCurrentSubscription().then((subscription) => {
            setSubscription(subscription);
        })

        setPartToShow('plans');
    }

    let modalContent = null;

    if (invoiceForModification?.total < 0) {
        modalContent = (
            <Modal title="Changement d'abonnement" open={isModalModifyOpen} onOk={handleOk} onCancel={handleCancel}
                   okText={'Confirmer'} cancelText={'Annuler'}>
                <p>Vous vous apprêtez à changer d'abonnement. Le changement se fera automatiquement le {moment.unix(invoiceForModification.period_end).format("DD/MM/YYYY")}.</p>

                <Descriptions column={1} bordered>
                    <Descriptions.Item
                        label="Total">{(invoiceForModification?.lines.data[1].amount / 100).toFixed(2).toString().replace(/\./g, ',')}€ H.T.
                        / mois</Descriptions.Item>
                </Descriptions>
            </Modal>
        )
    } else {
        modalContent = (
            <Modal title="Changement d'abonnement" open={isModalModifyOpen} onOk={handleOk} onCancel={handleCancel}
                   okText={'Payer maintenant'} cancelText={'Annuler'}>
                <p>Vous vous apprêtez à changer d'abonnement. Le changement se fera immédiatement au prorata du tarif du
                    plan précédent. Puis l'abonnement se poursuivre aux mêmes dates avec le nouveau
                    tarif.</p>

                <Descriptions column={1} bordered>
                    <Descriptions.Item
                        label="Tarif du plan actuel déjà payé">{(invoiceForModification?.lines.data[0].amount / 100).toFixed(2).toString().replace(/\./g, ',')}€ H.T.</Descriptions.Item>
                    {(invoiceForModification?.lines.data.length > 1) ? (
                        <Descriptions.Item
                            label="Tarif du nouveau plan">{(invoiceForModification?.lines.data[1].amount / 100).toFixed(2).toString().replace(/\./g, ',')}€ H.T.</Descriptions.Item>) : null}
                    <Descriptions.Item
                        label="Sous-total">{(invoiceForModification?.subtotal / 100).toFixed(2).toString().replace(/\./g, ',')}€ H.T.</Descriptions.Item>
                    <Descriptions.Item
                        label="Total">{(invoiceForModification?.total / 100).toFixed(2).toString().replace(/\./g, ',')}€ H.T.</Descriptions.Item>
                </Descriptions>
            </Modal>
        )
    }

    let partToShowContent = null;

    if (partToShow === 'successfully_purchased') {
        partToShowContent = (
            <Row>
                <Col span={24}>
                    <Card>
                        <Result
                            status="success"
                            title="Paiement réalisé avec succès !"
                            subTitle="Vous pouvez profiter dès maintenant de vos nouveaux avantages"
                            extra={[
                                <Button type="primary" key="console" onClick={onClickSuccessfullyPurchaseHandler}>
                                    Fermer
                                </Button>,
                            ]}
                        />
                    </Card>
                </Col>
            </Row>
        )
    } else if (partToShow === 'plans') {
        partToShowContent = (
            <Row gutter={16}>
                {offers.map(offer =>
                    <Col span={6}>
                        <Badge.Ribbon style={{display: offer.badgeText || 'none'}} text={offer.badgeText}>
                            <div style={{
                                backgroundColor: 'white',
                                borderRadius: "8px",
                                padding: "25px",
                                textAlign: "center"
                            }}>
                                <div style={{
                                    height: "450px"
                                }}>
                                    <div style={{
                                        height: "90px"
                                    }}>
                                        <div style={{
                                            fontSize: "24px",
                                            fontWeight: "bold",
                                            margin: "15px 0 15px"
                                        }}>{offer.title}</div>
                                        <div>{offer.subtitle}</div>
                                    </div>
                                    <div className={'plan-price'}>{((offer.price === 0) ? offer.price : offer.price.toFixed(2)).toString().replace(/\./g, ',')}<span
                                        style={{
                                            fontSize: "42px"
                                        }}>€</span><span className={'plan-periodicity'}> {offer.priceMode}</span></div>
                                    <div style={{
                                        padding: "0 0 50px 0"
                                    }}>
                                        {offer.description}
                                    </div>
                                </div>
                                <div style={{
                                    textAlign: "center"
                                }}>
                                    <BigButton offer={offer} subscription={subscription} planInProgress={planInProgress}
                                               setSelectedStripePriceId={setSelectedStripePriceId}
                                               setPlanInProgress={setPlanInProgress}
                                               setIsModalUnsubscribeOpen={setIsModalUnsubscribeOpen}
                                               createCheckoutSession={createCheckoutSession}/>
                                </div>
                            </div>
                        </Badge.Ribbon>
                    </Col>
                )}
            </Row>
        );
    }

    return (
        <>
            <PageHeader
                className={'site-layout-sub-header-background'}
                ghost={false}
                onBack={() => window.history.back()}
                title="Abonnement / Facturation"
                extra={[]}
                style={{
                    marginBottom: "18px"
                }}
            >
            </PageHeader>

            {(subscription && subscription.stripe_price_id !== null) ? (<Card style={{
                marginBottom: "18px"
            }}>
                <a onClick={myInvoices} type="link">Mes factures</a> <Divider type="vertical"/> <a onClick={myInvoices}
                                                                                                   type="link">Mes
                moyens de paiements</a>
            </Card>) : null}

            {partToShowContent}

            {modalContent}

            <Modal title="Changement d'abonnement" open={isModalUnsubscribeOpen} onOk={handleUnsubscribeOk}
                   onCancel={handleUnsubscribeCancel}
                   okText={'Confirmer'} cancelText={'Annuler'}>
                <p>Vous vous apprêtez à passer sur l'abonnement gratuit limité. Le changement se fera automatiquement le
                    mois prochain.</p>
            </Modal>
        </>
    )
}

export default BillingPage;

