import {jsonFetch} from "./JsonFetch";

let Company = {

    /**
     *
     */
    create: async (payload) => {
        let url = '/company';

        const response = await jsonFetch('POST', url, payload);
        if (response.ok) {
            return response.json();
        }
        throw Error(response.statusText);
    },

}

export {Company};
