import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import {Button, Col, Form, Input, Layout, notification, Row} from "antd";
import {LockOutlined, UserOutlined} from "@ant-design/icons";

import LayoutPage from "./LayoutIdentificationPages";
import ory from "../../helpers/ory";

const { Content } = Layout;

const Login = () => {
    const [loading, setLoading] = useState(false);
    const [formLogin] = Form.useForm();

    const [session, setSession] = useState()
    const [logoutUrl, setLogoutUrl] = useState()

    const [flow, setFlow] = useState()
    const navigate = useNavigate();

    // Returns either the email or the username depending on the user's Identity Schema
    const getUserName = (identity) =>
        identity.traits.email || identity.traits.username

    // Second, gather session data, if the user is not logged in, redirect to login
    useEffect(() => {
        ory
            .toSession()
            .then(({data}) => {
                navigate('/');
            })
            .catch(() => {
                ory
                    .initializeSelfServiceLoginFlowForBrowsers(true, undefined, undefined)
                    .then(({data}) => {
                        setFlow(data)
                        navigate(`/login?flow=${data?.id}`, undefined, {shallow: true})
                    })
            })
    }, [])

    const onSubmit = (values) => {
        setLoading(true);
        ory.submitSelfServiceLoginFlow(flow.id, {
            identifier: values['username'],
            method: "password",
            password: values['password'],
            csrf_token: flow.ui.nodes[0].attributes.value
        }).then(() => {
            navigate(`/contacts`);
        }).catch(() => {
            notification['error']({
                message: "L'authentification a échouée"
            });
        }).finally(() => {
            setLoading(false);
        });
    }

    const onFinish = () => {
        let values = formLogin.getFieldsValue();
        onSubmit(values)
    }

    return (
        <LayoutPage title="Identification">
            <Content>
                <Form
                    form={formLogin}
                    name="normal_login"
                    className="login-form"
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: "Le nom d'utilisateur ne peut pas être vide",
                            },
                        ]}
                    >
                        <Input
                            prefix={<UserOutlined className="site-form-item-icon"/>}
                            placeholder="Nom d'utilisateur"
                        />
                    </Form.Item>

                    <Form.Item
                        className="mb-2"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: "Le password ne peut pas être vide",
                            },
                        ]}
                    >
                        <Input
                            prefix={<LockOutlined className="site-form-item-icon"/>}
                            type="password"
                            placeholder="Mot de passe"
                        />
                    </Form.Item>

                    <Row>
                        <Col span={24}>
                            <Form.Item className="mt-0">
                                <a className="login-form-forgot" href="/login/recovery/request" style={{float: 'right'}}>
                                    mot de pass oublié ?
                                </a>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button" loading={loading}>
                            Connexion
                        </Button>
                    </Form.Item>
                </Form>
            </Content>
        </LayoutPage>
    )
}

export default Login;
