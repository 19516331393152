import {Button, Card, Col, Form, Input, notification, Row} from "antd";
import React, {useEffect, useState} from "react"
import {Profile} from "../api/Profile";
import ory from "../helpers/ory";
import useForm from "antd/es/form/hooks/useForm";
import {PageHeader} from "@ant-design/pro-components";
import {Config} from "../config";


/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
function ProfilePage() {

    const [profile, setProfile] = useState(null);
    const [passwordUpdateLoading, setPasswordUpdateLoading] = useState(false)

    let [generalForm] = useForm();
    let [sponsorForm] = useForm();

    useEffect(() => {
        loadProfile().then((profile) => {
            generalForm.setFieldsValue({
                'firstname': profile.firstname,
                'lastname': profile.lastname,
            })

            sponsorForm.setFieldsValue({
                'sponsorUrl': Config.appUrl + '/register?sponsor=' + profile.sponsor_id,
            })
        });
    }, [])

    let loadProfile = () => {
        return Profile.me().then(profile => {
            setProfile(profile);
            return profile;
        });
    }

    let onFinishPasswordHandler = (values) => {

        if (values.password !== values.password_confirmation) {
            notification['error']({
                message: "Les mots de passes ne sont pas identiques"
            })

            return;
        }

        setPasswordUpdateLoading(true)

        ory.initializeSelfServiceSettingsFlowForBrowsers().then(res => {
            let {data} = res;
            ory.submitSelfServiceSettingsFlow(data.id, {
                password: values.password,
                method: 'password',
                csrf_token: data.ui.nodes[0].attributes.value
            }).then(() => {
                notification['success']({
                    message: "Le mot de passe été changé avec succès"
                })
            }).catch(() => {
                notification['error']({
                    message: "Impossible de changer le mot de passe"
                })
            }).finally(() => setPasswordUpdateLoading(false));
        }).catch(() => {
            notification['error']({
                message: "Impossible de changer le mot de passe"
            })

            setPasswordUpdateLoading(false);
        });
    }

    let sponsorCard = null;
    let allowedSponsors = [1, 3];
    if (profile && allowedSponsors.indexOf(profile.company_id) > -1) {
        sponsorCard = (
            <Card title="Parrainage">
                <Form layout="vertical" form={sponsorForm}>
                    <Form.Item name={'sponsorUrl'} label={"Lien"}>
                        <Input readOnly={true}/>
                    </Form.Item>
                </Form>
            </Card>
        )
    }

    return (
        <>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <PageHeader
                        className={'site-layout-sub-header-background'}
                        ghost={false}
                        title={profile?.firstname + ' ' + profile?.lastname}
                        extra={[]}
                    >
                    </PageHeader>
                </Col>
                <Col span={12}>
                    <Card title="Informations générales" style={{marginBottom: "10px"}}>
                        <Form layout="vertical" form={generalForm}>
                            <Form.Item name={'lastname'} label={"Nom"}>
                                <Input disabled={true}/>
                            </Form.Item>
                            <Form.Item name={'firstname'}  label={"Prénom"}>
                                <Input disabled={true}/>
                            </Form.Item>
                        </Form>
                    </Card>
                    {sponsorCard}
                </Col>
                <Col span={12}>
                    <Card title="Mot de passe">
                        <Form layout="vertical" onFinish={onFinishPasswordHandler}>
                            <Form.Item name={'password'} label={"Nouveau mot de passe"} rules={[
                                {
                                    required: true,
                                    message: "Le mot de passe ne peut pas être vide",
                                },
                            ]}>
                                <Input.Password/>
                            </Form.Item>
                            <Form.Item name={'password_confirmation'} l label={"Confirmation"} rules={[
                                {
                                    required: true,
                                    message: "La confirmation ne peut pas être vide",
                                },
                            ]}>
                                <Input.Password/>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType={'submit'}
                                        loading={passwordUpdateLoading}>Modifier</Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default ProfilePage;

