import {Button, Card, DatePicker, Divider, Form, Image, Input, Space, TimePicker} from "antd";
import React, {useEffect, useState} from "react"
import {AppleOutlined, FileOutlined, GoogleOutlined, WindowsOutlined} from "@ant-design/icons";
import ical from "ical-generator";
import {useSearchParams} from "react-router-dom";
import logo from '../assets/Skani.png';
import moment from "moment";
import {Config} from "../config";
import useForm from "antd/es/form/hooks/useForm";

const {TextArea} = Input;

const actionsLabel = {
    1: 'par téléphone',
    2: 'sur Linkedin',
    3: 'par email'
}

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
function EventCal() {

    const [shortLink, setShortLink] = useState(null);
    const [searchParams] = useSearchParams();

    let [form] = useForm();

    useEffect(() => {

        fetch(Config.apiUrl + '/pipeline/short_link/' + searchParams.get('link') + '/public').then((resp) => {
            return resp.json();
        }).then((shortLink) => {
            setShortLink(shortLink);
        });

    }, [])

    useEffect(() => {

        if (shortLink !== null) {
            let startNow = moment();
            startNow.add(2, 'hours')

            let endNow = moment(startNow);
            endNow.add(15, 'minutes');

            form.setFieldsValue({
                'title': defineTitle(),
                'description': defineDescription(),
                'start_date': startNow,
                'start_time': startNow,
                'end_date': endNow,
                'end_time': endNow,
            })
        }
    }, [shortLink])

    /**
     *
     * @returns {string}
     */
    const defineBestLabel = () => {
        if (shortLink['meta']['contact_firstname'] && shortLink['meta']['contact_lastname']) {
            return shortLink['meta']['contact_firstname'] + ' ' + shortLink['meta']['contact_lastname']
        } else if (shortLink['meta']['contact_email']) {
            return shortLink['meta']['contact_email']
        } else if (shortLink['meta']['contact_phone']) {
            return shortLink['meta']['contact_phone']
        } else {
            return null
        }
    }

    /**
     *
     * @returns {*}
     */
    const defineActionLabel = () => {
        if (shortLink['meta']['action_id']) {
            return actionsLabel[shortLink['meta']['action_id']]
        }

        return '';
    }

    /**
     *
     * @returns {string}
     */
    const defineTitle = () => {
        return defineBestLabel() + ' est à contacter ' + defineActionLabel();
    }

    const defineDescription = () => {
        let documentName = shortLink['meta']['document_name']
        let actionDatetime = moment(shortLink['meta']['action_datetime']).format('D/M/Y à HH:mm')
        let contactUrl = buildContactUrl()

        const template =

            `${defineBestLabel()} s'est intéressé de très près à votre contenu "${documentName}" le "${actionDatetime}" !
    
Notre conseil : Relancez rapidement dans 24h. Évoquer le thème de la page la plus consultée.
    
Voir sa fiche : ${contactUrl}`;

        return template
    }

    const buildContactUrl = () => {
        return Config.appUrl + '/contacts/' + shortLink['meta']['contact_id']
    }

    /**
     *
     * @param e
     */
    const handleMenuClick = (key) => {

        form.validateFields().then(() => {

            let data = form.getFieldsValue();
            let summary = '🚨SKANIGo° - ' + data.title;
            let description =

                `
Notification SKANIGo° !

${data.description}
            `
            const startTime = data.start_date;
            startTime.set('hours', data.start_time.hours());
            startTime.set('minutes', data.start_time.minutes());

            const endTime = data.end_date;
            endTime.set('hours', data.end_time.hours());
            endTime.set('minutes', data.end_time.minutes());

            if (key == '1' || key == '2' || key == '3') {

                const calendar = ical({name: data.title});

                calendar.createEvent({
                    start: startTime,
                    end: endTime,
                    summary: summary,
                    description: description
                });

                var fileName = "event.ics";

                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                var url = window.URL.createObjectURL(calendar.toBlob());
                a.href = url;
                a.download = fileName;
                a.click();
                window.URL.revokeObjectURL(url);
            } else if (key == '4' || key == '7') {
                let params = {
                    body: description,
                    subject: summary,
                    path: '/calendar/action/compose',
                    location: null,
                    rru: 'addevent',
                    enddt: endTime.toISODate(),
                    startdt: startTimee.toISODate(),
                }

                let queryString = new URLSearchParams(params).toString();
                window.open('https://outlook.office.com/calendar/0/deeplink/compose?' + queryString)
            } else if (key == '5') {
                // 20221113T074600Z
                let startDateFormat = startTime.format('YYYYMMDD[T]HHmmssZ');
                let endDateFormat = endTime.format('YYYYMMDD[T]HHmmssZ');

                let googleCalendar = {
                    'action': 'TEMPLATE',
                    'text': summary,
                    'details': description,
                    'location': null,
                    'dates': startDateFormat + '/' + endDateFormat,
                    'ctz': 'europe/paris',
                }

                let queryString = new URLSearchParams(googleCalendar).toString();
                window.open('https://www.google.com/calendar/render?' + queryString)
            } else if (key == '6') {

            }
        });
    };
    const items = [
        {
            label: 'Fichier .ical',
            key: '1',
            icon: <FileOutlined/>,
        }, {
            label: 'Apple',
            key: '2',
            icon: <AppleOutlined/>,
        }, {
            label: 'Outlook',
            key: '3',
            icon: <WindowsOutlined/>,
        },
        {
            label: 'Outlook.com',
            key: '4',
            icon: null,
        },
        {
            label: 'Google',
            key: '5',
            icon: <GoogleOutlined/>,
        },
        // {
        //     label: 'Yahoo',
        //     key: '6',
        //     icon: null,
        // },
        {
            label: 'Office 365',
            key: '7',
            icon: null,
        },
    ];
    const menuProps = {
        items,
        onClick: handleMenuClick,
    };

    const pickersProps = {
        size: "large",
        inputReadOnly: true,
        style: {'width': '100%'}
    }

    if (shortLink === null) {
        return (<>Chargement ...</>)
    }

    return (
        <>
            <div className={"event-calendar-box"}>
                <Image src={logo} alt="Logo" height={50} width={'auto'} preview={false} style={{margin: "0 0 50px 0"}}/>
                <Card title="Création d'un évènement">
                    <Form form={form} layout="vertical" onFinish={() => {
                    }}>
                        <Form.Item name={'title'} label={"Titre"} rules={[
                            {
                                required: true,
                                message: "Le titre ne peut pas être vide",
                            },
                        ]}>
                            <Input size={"large"}/>
                        </Form.Item>
                        <Form.Item name={'start_date'} label={"Date de début"} rules={[
                            {
                                required: true,
                                message: "La date ne peut pas être vide",
                            },
                        ]}>
                            <DatePicker {...pickersProps} format={'DD/MM/YYYY'}/>
                        </Form.Item>
                        <Form.Item name={'start_time'} label={"Heure de début"} rules={[
                            {
                                required: true,
                                message: "L'heure ne peut pas être vide",
                            },
                        ]}>
                            <TimePicker {...pickersProps} format={'HH:mm'}/>
                        </Form.Item>
                        <Form.Item name={'end_date'} label={"Date de fin"} rules={[
                            {
                                required: true,
                                message: "La date ne peut pas être vide",
                            },
                        ]}>
                            <DatePicker {...pickersProps} format={'DD/MM/YYYY'}/>
                        </Form.Item>
                        <Form.Item name={'end_time'} label={"Heure de fin"} rules={[
                            {
                                required: true,
                                message: "L'heure ne peut pas être vide",
                            },
                        ]}>
                            <TimePicker {...pickersProps} format={'HH:mm'}/>
                        </Form.Item>
                        <Form.Item name={'description'} label={"Description"} rules={[
                            {
                                required: true,
                                message: "La confirmation ne peut pas être vide",
                            },
                        ]}>
                            <TextArea rows={9}/>
                        </Form.Item>
                        <Divider orientation="left">Créer sur </Divider>
                        <Form.Item>
                            <Space wrap={true}>
                                {items.map((item) => (
                                    <Button icon={item.icon} size={'large'} onClick={() => {
                                        handleMenuClick(item.key)
                                    }}>{item.label}</Button>
                                ))}
                            </Space>
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        </>
    )
}

export default EventCal;

