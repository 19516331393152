import {AutoComplete, Button, Col, Drawer, Form, Input, notification, Result, Row, Space, Table, Upload} from "antd";
import React, {useEffect, useState} from "react"

import {DeleteOutlined, EyeOutlined, LinkOutlined, PlusCircleOutlined, PlusOutlined, SmileOutlined} from '@ant-design/icons';
import {Config} from "../config";
import {Document} from "../api/Document";
import {Contact} from "../api/Contact";
import {SharedDocument} from "../api/SharedDocument";
import {DateTime} from "luxon";
import {CopyToClipboard} from "react-copy-to-clipboard/src";
import {Link} from "react-router-dom";
import ContactModal from "../components/ContactModal";
import {PageHeader} from "@ant-design/pro-components";
import {Quota} from "../api/Quota";

const {Dragger} = Upload;

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
function SharedDocumentsPage() {

    const [visibleSharedDocumentDrawer, setVisibleSharedDocumentDrawer] = useState(false);
    const [sharedDocumentIdInEdit, setSharedDocumentIdInEdit] = useState(false);
    const [data, setData] = useState(false);

    const columns = [
        {
            title: 'Nom du document',
            render: (row) => (<>{row.document.name}</>)
        },
        {
            title: 'Nom du contact',
            render: (row) => {
                if (row.contact) {
                    let libelle = (<i>aucune désignation</i>);
                    if (row.contact?.firstname !== null && row.contact?.lastname !== null) {
                        libelle = (<>{row.contact?.firstname} {row.contact?.lastname}</>)
                    } else if (row.contact.email !== null) {
                        libelle = (<>{row.contact.email}</>)
                    } else if (row.contact.mobile_phone !== null) {
                        libelle = (<>{row.contact.mobile_phone}</>)
                    }
                    return (<Link target={'_blank'} to={"/contacts/" + row.contact_id}>{libelle}</Link>)
                } else {
                    return (<i>en collecte</i>)
                }
            }
        },
        {
            title: 'Date de création',
            width: '180px',
            render: (row) => (<>
                {DateTime.fromISO(row.created_at).toLocaleString(DateTime.DATETIME_SHORT)}
            </>)
        },
        {
            width: '100px',
            render: (row) => (<Space>
                    <Button shape="circle" icon={<EyeOutlined/>} onClick={() => {
                        onViewSharedDocumentHandler(row.document.public_id)
                    }}/>
                    <CopyToClipboard text={Config.prodokLink + '?link=' + row.short_link.public_id}
                                     onCopy={onCopyLinkHandler}>
                        <Button shape="circle" icon={<LinkOutlined/>}/>
                    </CopyToClipboard>
                    </Space>
            ),
        },
    ];

    useEffect(() => {
        loadShares();
    }, []);

    const loadShares = () => {
        SharedDocument.getAll({}).then((json) => {
            setData(json);
        });
    }

    const onViewSharedDocumentHandler = (document_public_id) => {
        window.open(Config.prodokLink + '?metaf=' + document_public_id, '_blank')
    }

    const onAddSharedDocumentHandler = () => {
        setVisibleSharedDocumentDrawer(true);
    }

    const onCopyLinkHandler = () => {
        notification['info']({
            message: "Le lien a été copié dans le presse-papier. Vous pouvez des maintenant le coller ou vous le souhaitez"
        })
    }

    const onSaveDocumentDrawerHandler = () => {
        closeSharedDocumentDrawer();
        loadShares();
        setSharedDocumentIdInEdit(null);
    }

    const onCloseSharedDocumentDrawerHandler = () => {
        closeSharedDocumentDrawer();
    }

    const closeSharedDocumentDrawer = () => {
        setSharedDocumentIdInEdit(null);
        setVisibleSharedDocumentDrawer(false);
    }

    return (
        <>
            <PageHeader
                className={'site-layout-sub-header-background'}
                ghost={false}
                onBack={() => window.history.back()}
                title="Documents suivis"
                subTitle="Retrouvez ici l'historique des documents suivis"
                extra={[
                    <Button shape="circle" icon={<PlusCircleOutlined/>} type="primary"
                            onClick={onAddSharedDocumentHandler}></Button>
                ]}
            >
                <Table
                    columns={columns}
                    dataSource={data}
                />

                <SharedDocumentDrawer visible={visibleSharedDocumentDrawer} sharedDocumentId={sharedDocumentIdInEdit}
                                      onSave={onSaveDocumentDrawerHandler}
                                      onClose={onCloseSharedDocumentDrawerHandler}/>
            </PageHeader>
        </>
    )
}

const mockVal = (str, repeat = 1) => ({
    value: str.repeat(repeat),
});

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function SharedDocumentDrawer(props) {

    const [form] = Form.useForm();
    const [sharedDocumentInEdit, setSharedDocumentInEdit] = useState(null);
    const [contactOptions, setContactOptions] = useState([]);
    const [documentOptions, setDocumentOptions] = useState([]);
    const [selectedDocumentId, setSelectedDocumentId] = useState(null);
    const [selectedContactId, setSelectedContactId] = useState(null);
    const [showContactModal, setShowContactModal] = useState(false);
    const [quota, setQuota] = useState(null);

    useEffect(() => {
        form.resetFields();
        if (props.sharedDocumentId) {
            SharedDocument.getOne(props.sharedDocumentId).then(document => {
                setSharedDocumentInEdit(document);
                /*                form.setFieldsValue({
                                    name: document.name
                                })*/
            });
        }
    }, [props.sharedDocumentId]);

    useEffect(() => {
        if (props.visible) {
            Quota.getAvailableQuota().then(data => {
                setQuota(data);
            });
        }
    }, [props.visible]);

    const onSaveDrawerHandler = () => {
        let payload = form.getFieldsValue();
        payload.document_id = selectedDocumentId;
        payload.contact_id = selectedContactId;

        if (!props.sharedDocumentId || props.sharedDocumentId === null) {
            SharedDocument.create(payload).then(() => {
                props.onSave();
            });
        } else {
            SharedDocument.update(props.sharedDocumentId, payload).then(() => {
                props.onSave();
            });
        }
    }

    const title = () => {
        if (props.sharedDocumentId === null) {
            return "Partage d'un document";
        } else {
            return "Édition d'un partage";
        }
    }

    const defineBestContactLabel = (contact) => {
        if (contact.firstname !== null && contact.lastname !== null) {
            return {
                label: contact.firstname + " " + contact.lastname,
                value: contact.firstname + " " + contact.lastname,
                key: contact._id,
            }
        } else if (contact.email !== null) {
            return {
                label: contact.email,
                value: contact.email,
                key: contact._id,
            }
        } else if (contact.mobile_phone !== null) {
            return {
                label: contact.mobile_phone,
                value: contact.mobile_phone,
                key: contact._id,
            }
        }

        return {
            label: '',
            value: '',
            key: contact._id,
        };
    }

    const onSearchContactHandler = (searchText) => {
        Contact.getAll({
            q: {
                'fulltext': searchText
            }
        }).then((json) => {
            let options = json.map(defineBestContactLabel)
            setContactOptions(
                !searchText ? [] : options
            );
        })
    };

    const onSelectContactHandler = (value, option) => {
        setSelectedContactId(option.key);
    };

    const onSearchDocumentHandler = (searchText) => {
        Document.getAll({
            q: {
                'fulltext': searchText
            }
        }).then((json) => {
            let options = json.map((item) => {
                return {
                    value: item.name,
                    key: item.id,
                    label: item.name,
                }
            })
            setDocumentOptions(
                !searchText ? [] : options
            );
        })
    };

    const onSelectDocumentHandler = (value, option) => {
        setSelectedDocumentId(option.key);
    };

    const onClickAddNewContactHandler = () => {
        setShowContactModal(true);
    }

    const onSaveContactModalHandler = (contact) => {
        setShowContactModal(false);
        setSelectedContactId(contact._id)
        form.setFieldsValue({
            contact_id: defineBestContactLabel(contact)
        })
    }

    const onCloseContactModalHandler = () => {
        setShowContactModal(false)
    }

    let content = null;
    let quotaExceeded = (quota && quota.current <= 0);

    if (quotaExceeded) {
        content = (
            <Result
                status="success"
                title="Votre activité est à son max !"
                subTitle="Vous avez utilisé tout votre quota de lien pour ce mois-ci. Vous ne pouvez plus en créer de nouveaux."
                extra={[
                    <Link to={'/billing'}>
                        <Button type="primary" key="console">
                            Il est temps de changer de plan !
                        </Button>
                    </Link>
                ]}
            />
        )
    } else {
        content = (
            <Form
                form={form}
                layout="vertical"
            >
                <Form.Item label="Document à partager" required>
                    <Row gutter={8}>
                        <Col span={24}>

                            <Form.Item name="document_id">
                                <AutoComplete
                                    options={documentOptions}
                                    onSelect={onSelectDocumentHandler}
                                    onSearch={onSearchDocumentHandler}
                                >
                                    <Input.Search size="large" placeholder="Chercher ..."/>
                                </AutoComplete>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item label="Contact à qui partager le document (optionnel)">
                    <Row gutter={8}>
                        <Col span={22}>
                            <Form.Item name="contact_id">
                                <AutoComplete
                                    options={contactOptions}
                                    onSelect={onSelectContactHandler}
                                    onSearch={onSearchContactHandler}
                                >
                                    <Input.Search size="large" placeholder="Chercher ..."/>
                                </AutoComplete>
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Button icon={<PlusOutlined/>} size="large"
                                    onClick={onClickAddNewContactHandler}/>
                        </Col>
                    </Row>
                </Form.Item>
            </Form>
        )
    }

    return (
        <>
            <Drawer title={title()} placement="left" onClose={props.onClose}
                    visible={props.visible} width={700} extra={
                <Space>
                    <Button onClick={props.onClose}>Annuler</Button>
                    <Button onClick={onSaveDrawerHandler} type="primary" disabled={quotaExceeded}>
                        Générer le lien
                    </Button>
                </Space>
            }>
                {content}
                <ContactModal open={showContactModal} onSave={onSaveContactModalHandler}
                              onClose={onCloseContactModalHandler}></ContactModal>
            </Drawer>
        </>
    )
}

export default SharedDocumentsPage;

