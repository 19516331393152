import React, {useState} from "react";
import {Link} from "react-router-dom";

import {Button, Form, Input, Layout, notification, Result} from "antd";

import LayoutPage from "./LayoutEmailValidationPages";
import ory from "../../helpers/ory";

const { Content } = Layout;

const LoginRecoveryRequest = () => {
    const [loading, setLoading] = useState(false);
    const [formLogin] = Form.useForm();

    const [emailSent, setEmailSent] = useState(false);

    const onSubmit = (values) => {
        setLoading(true);

        ory.initializeSelfServiceRecoveryFlowForBrowsers().then((data) => {
            data = data.data;
            ory.submitSelfServiceRecoveryFlow(data.id, {
                email: values['email'],
                method: 'link',
                csrf_token: data.ui.nodes[0].attributes.value
            }).then(() => {
                setEmailSent(true)
            }).catch(() => {
                notification['error']({
                    message: "Impossible d'effectuer l'action"
                })
            }).finally(() => {
                setLoading(false);
            })
        }).catch(() => {
            setLoading(false);
            notification['error']({
                message: "Impossible d'effectuer l'action"
            })
        })
    }


    const onFinish = () => {
        let values = formLogin.getFieldsValue();
        onSubmit(values)
    }

    let pageResult = (
        <Form
            form={formLogin}
            onFinish={onFinish}
        >

            <Form.Item
                className="mb-2"
                name="email"
                rules={[
                    {
                        required: true,
                        message: "L'email ne peut pas être vide",
                    },
                ]}
            >
                <Input
                    type="email"
                    placeholder="Email"
                />
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit" className="login-form-button" loading={loading}>
                    Envoyer
                </Button>
            </Form.Item>
        </Form>
    )

    if (emailSent) {
        pageResult = (
            <Result
                status="success"
                title="Un email a été envoyé"
                subTitle="Après avoir cliqué sur le lien présent dans l'email vous serez automatiquement connecté. Vous pourrez alors changer votre mot de passe."
                extra={[
                    <Link to={"/login"} key={'login'}>
                        <Button type="primary" key="console">
                            Connexion
                        </Button>,
                    </Link>
                ]}
            />
        )
    }

    return (
        <LayoutPage title="Demande de changement de mot de passe">
            <Content>
                <div className="login-form-box">
                    {pageResult}
                </div>
            </Content>
        </LayoutPage>
    )
}

export default LoginRecoveryRequest;
