import {Button, Col, Form, message, Modal, notification, Row, Select, Spin, Steps, Table} from "antd";
import React, {useMemo, useRef, useState} from "react"

import {InboxOutlined} from '@ant-design/icons';

import {Config} from "../config";
import Dragger from "antd/es/upload/Dragger";
import debounce from 'lodash/debounce';
import {useForm} from "antd/es/form/Form";
import {Document} from "../api/Document";
import {Option} from "antd/es/mentions";
import {PageHeader} from "@ant-design/pro-components";
import {Quota} from "../api/Quota";

const {Step} = Steps;

function DebounceSelect({fetchOptions, debounceTimeout = 800, ...props}) {
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState([]);
    const fetchRef = useRef(0);
    const debounceFetcher = useMemo(() => {
        const loadOptions = (value) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);
            fetchOptions(value).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }

                setOptions(newOptions);
                setFetching(false);
            });
        };

        return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);
    return (
        <Select
            labelInValue
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small"/> : null}
            {...props}
            options={options}
        />
    );
}

async function fetchUserList(username) {
    return Document.getAll({
        q: {
            'fulltext': username
        }
    }).then((json) => json.map((document) => ({
        label: document.name,
        value: document.id,
    })));
}

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
function ListGenerator() {

    const [formMapping] = useForm();
    const fetchRef = useRef(0);

    const [selectedFileToUpload, setSelectedFileToUpload] = useState(null);
    const [step, setStep] = useState(0);
    const [options, setOptions] = useState([]);
    const [fetching, setFetching] = useState(false);
    const [isLowQuotaModalOpen, setIsLowQuotaModalOpen] = useState(false);
    const [filePreviewUploding, setFilePreviewUploding] = useState(false);
    const [fileGenerationUploding, setFileGenerationUploding] = useState(false);
    const [selectedDocuments, setSelectedDocuments] = useState([]);

    let onFinishFirstStepFormHandler = () => {
        setStep(1)
    }

    let onClickNextStep1Handler = () => {
        setStep(2)
    }

    let onClickPrevStep1Handler = () => {
        setSelectedFileToUpload(null);
        setStep(0)
    }

    let onClickNextStep2Handler = () => {
        setStep(3)
    }

    let onClickPrevStep2Handler = () => {
        setStep(1)
    }

    let processFile = () => {
        setFileGenerationUploding(true);

        var data = new FormData()
        data.append('file', selectedFileToUpload.originFileObj)
        data.append('delimiter', selectedFileToUpload.response.preview.delimiter)

        data.append('email_key', formMapping.getFieldValue('email'))
        data.append('firstname_key', formMapping.getFieldValue('firstname'))
        data.append('lastname_key', formMapping.getFieldValue('lastname'))
        data.append('phone_key', formMapping.getFieldValue('phone'))
        data.append('mobile_phone_key', formMapping.getFieldValue('mobile_phone'))

        data.append('fieldnames', selectedFileToUpload.response.preview.headers)
        data.append('document_ids', selectedDocuments.map((item) => {
            return item.value
        }))

        fetch(Config.apiUrl + '/csv/file/generate', {
            method: 'POST',
            credentials: 'include',
            body: data
        }).then(() => {
            notification['info']({
                message: 'Votre fichier est en cours de génération',
                description: "Vous pourrez le retrouver dans votre centre de notification quand il sera prêt."
            });
        }).finally(() => {
            setFileGenerationUploding(false);
            setStep(0);
            setSelectedFileToUpload(null);
        })
    }

    let onClickNextStep3Handler = async () => {

        let quota = await Quota.getAvailableQuota();

        if (quota.current < selectedFileToUpload.response.preview.total_rows - 1) {
            setIsLowQuotaModalOpen(true);
        } else {
            processFile();
        }
    }

    let onClickPrevStep3Handler = () => {
        setStep(2)
    }

    const uploadProps = {
        name: 'file',
        multiple: false,
        showUploadList: true,
        action: Config.apiUrl + '/csv/file/preview',

        onChange(info) {
            const {status} = info.file;

            if (status !== 'uploading') {
                setFilePreviewUploding(false)
            } else {
                setFilePreviewUploding(true)
            }

            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
                setStep(1)
                setSelectedFileToUpload(info.file);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },

        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    let currentContentStep = (
        <>
            <div style={{
                "marginBottom": "20px"
            }}>
                <Dragger {...uploadProps}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined/>
                    </p>
                    <p className="ant-upload-text">Cliquez ou déplacez <br/>le fichier .csv dans cette zone</p>
                </Dragger>
            </div>
            <Row>
                <Col span={24}><Button onClick={onFinishFirstStepFormHandler} loading={filePreviewUploding} disabled={selectedFileToUpload === null}>Suivant</Button></Col>
            </Row>
        </>
    )

    let columns = [];
    let dataSource = [];

    if (selectedFileToUpload !== null) {
        columns = selectedFileToUpload.response.preview.headers.map((item) => {
            return {
                title: item,
                dataIndex: item,
                key: item,
                ellipsis: true,
            };
        });

        dataSource = selectedFileToUpload.response.preview.rows.map((item) => {
            return item;
        });
    }

    if (step == 1) {
        currentContentStep = (
            <>
                <div style={{
                    height: "500px"
                }}>
                    <Table size={"small"} dataSource={dataSource} pagination={{pageSize: 10}} columns={columns}
                           scroll={{
                               x: true
                           }}/>
                </div>

                <Row>
                    <Col span={24}><Button onClick={onClickPrevStep1Handler}>Retour</Button> <Button
                        onClick={onClickNextStep1Handler}>Suivant</Button></Col>
                </Row>
            </>
        )
    } else if (step == 2) {
        let headerOptions = selectedFileToUpload.response.preview.headers.map((item) => {
            return (<Option value={item}>{item}</Option>)
        })

        formMapping.setFieldsValue({
            'email': (selectedFileToUpload.response.preview.headers.indexOf('email') > -1) ? 'email' : null,
            'firstname': (selectedFileToUpload.response.preview.headers.indexOf('firstname') > -1) ? 'firstname' : null,
            'lastname': (selectedFileToUpload.response.preview.headers.indexOf('lastname') > -1) ? 'lastname' : null,
            'phone': (selectedFileToUpload.response.preview.headers.indexOf('phone') > -1) ? 'phone' : null,
            'mobile_phone': (selectedFileToUpload.response.preview.headers.indexOf('mobile_phone') > -1) ? 'mobile_phone' : null,
        })

        currentContentStep = (
            <>
                <Form
                    form={formMapping}
                    layout="vertical"

                >
                    <Form.Item label="Champ email" name={'email'}>
                        <Select>
                            {headerOptions}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Champ prénom" name={'firstname'}>
                        <Select>
                            {headerOptions}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Champ nom" name={'lastname'}>
                        <Select>
                            {headerOptions}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Champ téléphone fixe" name={'phone'}>
                        <Select>
                            {headerOptions}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Champ téléphone mobile" name={'mobile_phone'}>
                        <Select>
                            {headerOptions}
                        </Select>
                    </Form.Item>
                </Form>

                <Row>
                    <Col span={24}><Button onClick={onClickPrevStep2Handler}>Retour</Button> <Button
                        onClick={onClickNextStep2Handler}>Suivant</Button></Col>
                </Row>
            </>
        )
    } else if (step == 3) {
        currentContentStep = (
            <>
                <div style={{
                    height: "500px"
                }}>
                    <DebounceSelect
                        mode="multiple"
                        value={selectedDocuments}
                        placeholder="Choisissez un ou plusieurs documents"
                        fetchOptions={fetchUserList}
                        onChange={(newValue) => {
                            setSelectedDocuments(newValue);
                        }}
                        style={{
                            width: '100%',
                        }}
                    />
                </div>

                <Row>
                    <Col span={24}><Button onClick={onClickPrevStep3Handler}>Retour</Button> <Button
                        onClick={onClickNextStep3Handler} disabled={selectedDocuments.length === 0} loading={fileGenerationUploding}>Lancer la génération du fichier</Button></Col>
                </Row>
            </>
        )
    }

    return (
        <>
            <PageHeader
                className={'site-layout-sub-header-background'}
                ghost={false}
                onBack={() => window.history.back()}
                title="Suivi de liste"
                extra={[]}
            >
                <Steps current={step}>
                    <Step title="Envoi du fichier source"/>
                    <Step title="Aperçu"/>
                    <Step title="Mappage"/>
                    <Step title="Actions"/>
                </Steps>
                <div className="steps-content" style={{
                    margin: "25px 0 0 0",
                }}>
                    {currentContentStep}
                </div>
            </PageHeader>
            <Modal onOk={ () => processFile() } onCancel={() => setIsLowQuotaModalOpen(false)} title="Votre quota est insuffisant" open={isLowQuotaModalOpen} footer={[
                <Button key="back" onClick={() => setIsLowQuotaModalOpen(false)}>
                    Fermer
                </Button>,
                <Button key="submit" type="primary" onClick={() => {processFile(); setIsLowQuotaModalOpen(false);}}>
                    Traiter le fichier
                </Button>,
                <Button
                    key="link"
                    target={'_blank'}
                    href="/billing"
                    type="primary"
                >
                    Augmenter mon quota
                </Button>,
            ]}>
                <p>Le nombre de contacts présents dans votre fichier .csv dépasse votre quota disponible. Si vous continuez, une partie de vos contacts ne seront pas traités.</p>
                <p>Néanmoins, vous pouvez dès maintenant augmenter votre quota et reprendre le traitement.</p>
            </Modal>
        </>
    )
}

export default ListGenerator;

