import {Button, Col, Divider, Form, Input, notification, Popconfirm, Row, Space, Table,} from "antd";
import React, {useEffect, useState} from "react"
import {Contact} from "../api/Contact";
import {DeleteOutlined, ExportOutlined, EyeOutlined, ImportOutlined, PlusCircleOutlined} from "@ant-design/icons";
import {DateTime} from "luxon";
import ContactModal from "../components/ContactModal";
import {useNavigate, useParams} from "react-router-dom";
import ImportContactsModal from "../components/ImportContactsModal";
import {Config} from "../config";
import {PageHeader} from "@ant-design/pro-components";
import useForm from "antd/es/form/hooks/useForm";


/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
function ContactsPage() {

    const [contactModalVisible, setContactModalVisible] = useState(false);
    const [importContactsModalVisible, setImportsContactModalVisible] = useState(false);
    const [contactIdInEdit, setContactIdInEdit] = useState(false);
    const [filterLoading, setFilterLoading] = useState(false);
    const [contactsLoading, setContactsLoading] = useState(false);
    const [loadingDeleteSelectedRow, setLoadingDeleteSelectedRow] = useState(false);
    const [contacts, setContacts] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [filterForm] = useForm();

    let {id} = useParams();
    let navigate = useNavigate();

    const columns = [
        {
            title: 'Prénom',
            dataIndex: 'firstname',
            sorter: (a, b) => {
                a = a.firstname ? a.firstname : '';
                b = b.firstname ? b.firstname : '';
                return a.localeCompare(b)
            }
        },
        {
            title: 'Nom',
            dataIndex: 'lastname',
            sorter:  (a, b) => {
                a = a.lastname ? a.lastname : '';
                b = b.lastname ? b.lastname : '';
                return a.localeCompare(b)
            }
        },
        {
            title: 'Email',
            dataIndex: 'email',
            sorter:  (a, b) => {
                a = a.email ? a.email : '';
                b = b.email ? b.email : '';
                return a.localeCompare(b)
            }
        },
        {
            title: 'Téléphone mobile',
            dataIndex: 'mobile_phone',
            sorter: (a, b) => a.mobile_phone < b.mobile_phone
        },
        {
            title: 'Entreprise',
            dataIndex: 'company',
            sorter:  (a, b) => {
                a = a.company ? a.company : '';
                b = b.company ? b.company : '';
                return a.localeCompare(b)
            }
        },
        {
            title: 'Dernière activité',
            width: '180px',
            sorter: (a, b) => {
                a = a.behaviour.last_event_at ? a.behaviour.last_event_at : '';
                b = b.behaviour.last_event_at ? b.behaviour.last_event_at : '';
                return a.localeCompare(b)
            },
            render: (row) => (<>
                {(row.behaviour && row.behaviour.last_event_at) ? DateTime.fromISO(row.behaviour.last_event_at).toLocaleString(DateTime.DATETIME_SHORT) : null}
            </>)
        },
        {
            title: 'Date de création',
            width: '180px',
            defaultSortOrder: 'descend',
            sorter: (a, b) => {
                a = a.created_at ? a.created_at : '';
                b = b.created_at ? b.created_at : '';
                return a.localeCompare(b)
            },
            render: (row) => (<>
                {(row.created_at) ? DateTime.fromISO(row.created_at).toLocaleString(DateTime.DATETIME_SHORT) : null}
            </>)
        },
        {
            width: "100px",
            render: (row) => (
                <Space>
                    <Button shape="circle" icon={<EyeOutlined/>} onClick={() => {
                        onEditContactHandler(row._id)
                    }}/>
                    <Popconfirm 
                        placement="topLeft" 
                        title={"Confirmez-vous la suppression ?"}
                        onConfirm={() => onDeleteDocuementHandler(row._id)} 
                        okText="Oui" 
                        cancelText="Non"
                    >
                        <Button 
                            type="danger" 
                            shape="circle" 
                            icon={<DeleteOutlined/>} 
                        />
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    useEffect(() => {
        loadContacts();

        if (id) {
            editContact(id);
        }
    }, []);

    const loadContacts = (q) => {
        q = q || {};
        setContactsLoading(true);
        return Contact.getAll(q).then((json) => {
            setContacts(json);
            setContactsLoading(false);
        });
    }

    const editContact = (contactId) => {
        navigate('/contacts/' + contactId);
        setContactModalVisible(true);
        setContactIdInEdit(contactId);
    }

    const onEditContactHandler = (contactId) => {
        editContact(contactId);
    }

    const onDeleteDocuementHandler = (contactId) => {
        Contact.delete(contactId).then(() => {
            loadContacts();
            notification.success({
                message: 'Le contact a bien été suprimé.'
            })
        });
    }

    const onClickAddContactHandler = () => {
        setContactModalVisible(true);
    }

    const onCloseContactModalHandler = () => {
        closeContactModal();
    }

    const onSaveContactModalHandler = () => {
        closeContactModal();
        loadContacts();
    }

    const closeContactModal = () => {
        setContactIdInEdit(null);
        setContactModalVisible(false);
    }

    const onFinishFilterFormHandler = (values) => {
        setFilterLoading(true);
        loadContacts({
            q: values
        }).finally(() => {
            setFilterLoading(false)
        });
    }

    const onClickExportContactHandler = () => {
        let values = filterForm.getFieldsValue();
        window.open(Config.apiUrl + '/contacts/export?q=' + JSON.stringify(values))
    }

    const onClickImportContactHandler = () => {
        setImportsContactModalVisible(true);
    }

    const onCloseImportContactsModalHandler = () => {
        setImportsContactModalVisible(false);
    }

    const onConfirmDeleteSelectionHandler = () => {
        setLoadingDeleteSelectedRow(true);
        Contact.bulkDelete(selectedRowKeys).then(() => {
            loadContacts();
            setSelectedRowKeys([]);
        }).finally(() => {
            setLoadingDeleteSelectedRow(false);
        });
    }

    return (
        <>
            <PageHeader
                className={'site-layout-sub-header-background'}
                ghost={false}
                onBack={() => window.history.back()}
                title="Contacts"
                subTitle="Tous vos contacts"
                extra={[
                    <Button shape="round" icon={<ImportOutlined/>} onClick={onClickImportContactHandler}>
                        Importer
                    </Button>,
                    <Button shape="round" icon={<ExportOutlined/>} onClick={onClickExportContactHandler}>
                        Exporter
                    </Button>,
                    <Button shape="circle" icon={<PlusCircleOutlined/>} type="primary"
                            onClick={onClickAddContactHandler}/>
                ]}
            >

                <Form
                    form={filterForm}
                    layout="vertical"
                    onFinish={onFinishFilterFormHandler}
                >
                    <Form.Item
                        label="Recherche"
                        name="fulltext"
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" shape={'round'} htmlType="submit" loading={filterLoading}>Filtrer</Button>
                    </Form.Item>
                </Form>

                <Divider/>

                <Row style={{
                    marginBottom: '15px'
                }}>
                    <Col>
                        <Popconfirm placement="topLeft" title={"Confirmez-vous la suppression ?"}
                                    onConfirm={onConfirmDeleteSelectionHandler} okText="Oui" cancelText="Non">
                            <Button type="danger" shape={'round'} icon={<DeleteOutlined/>}
                                    loading={loadingDeleteSelectedRow}
                                    disabled={selectedRowKeys.length == 0}>
                                Supprimer
                            </Button>
                        </Popconfirm>
                    </Col>
                </Row>

                <Table
                    rowKey="_id"
                    rowSelection={{
                        type: "checkbox",
                        onChange: (selectedRowKeys, selectedRows) => {
                            setSelectedRowKeys(selectedRowKeys);
                        }
                    }}
                    loading={contactsLoading}
                    columns={columns}
                    dataSource={contacts}
                />
            </PageHeader>
            <ContactModal contactId={contactIdInEdit} open={contactModalVisible} onClose={onCloseContactModalHandler}
                          onSave={onSaveContactModalHandler}/>
            <ImportContactsModal contactId={contactIdInEdit} open={importContactsModalVisible}
                                 onClose={onCloseImportContactsModalHandler}/>
        </>
    )
}

export default ContactsPage;

