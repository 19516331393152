import {AutoComplete, Button, Col, Drawer, Form, Input, notification, Result, Row, Space, Table} from "antd";
import React, {useEffect, useState} from "react"

import {DeleteOutlined, LinkOutlined, PlusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import {Config} from "../config";
import {Contact} from "../api/Contact";
import {SharedDocument} from "../api/SharedDocument";
import {DateTime} from "luxon";
import {SharedUrl} from "../api/SharedUrl";
import {CopyToClipboard} from "react-copy-to-clipboard/src";
import ContactModal from "../components/ContactModal";
import {Link} from "react-router-dom";
import {PageHeader} from "@ant-design/pro-components";
import {Quota} from "../api/Quota";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
function SharedUrlsPage() {

    const [visibleSharedUrlDrawer, setVisibleSharedUrlDrawer] = useState(false);
    const [sharedUrlIdInEdit, setSharedUrlIdInEdit] = useState(false);
    const [data, setData] = useState(false);

    const columns = [
        {
            title: 'Url',
            render: (row) => (<><a href={row.url} target={"_blank"}>{row.url}</a></>)
        },
        {
            title: 'Nom du contact',
            render: (row) => {
                if (row.contact) {
                    let libelle = (<i>aucune désignation</i>);
                    if (row.contact?.firstname !== null && row.contact?.lastname !== null) {
                        libelle = (<>{row.contact?.firstname} {row.contact?.lastname}</>)
                    } else if (row.contact.email !== null) {
                        libelle = (<>{row.contact.email}</>)
                    } else if (row.contact.mobile_phone !== null) {
                        libelle = (<>{row.contact.mobile_phone}</>)
                    }
                    return (<Link target={"_blank"} to={"/contacts/" + row.contact_id}>{libelle}</Link>)
                } else {
                    return (<i>en collecte</i>)
                }
            }
        },
        {
            title: 'Date de création',
            width: '180px',
            render: (row) => (<>
                {DateTime.fromISO(row.created_at).toLocaleString(DateTime.DATETIME_SHORT)}
            </>)
        },
        {
            width: '110px',
            render: (row) => (<Space>
                    <CopyToClipboard text={Config.apiUrl + '/s/' + row.short_link.public_id} onCopy={onCopyLinkHandler}>
                        <Button shape="circle" icon={<LinkOutlined/>}/>
                    </CopyToClipboard>
                    <Button type="danger" shape="circle" icon={<DeleteOutlined/>} onClick={() => {
                        onDeleteSharedUrlHandler(row.id)
                    }} disabled={true}/></Space>
            ),
        },
    ];

    useEffect(() => {
        loadShares();
    }, []);

    const loadShares = () => {
        SharedUrl.getAll({}).then((json) => {
            setData(json);
        });
    }

    const onCopyLinkHandler = () => {
        notification['info']({
            message: "Le lien a été copié dans le presse-papier. Vous pouvez des maintenant le coller ou vous le souhaitez"
        })
    }

    const onAddSharedUrlHandler = () => {
        setVisibleSharedUrlDrawer(true);
    }

    const onDeleteSharedUrlHandler = (sharedUrlId) => {
        SharedUrl.delete(sharedUrlId).then(() => {
            loadShares();
        });
    }

    const onSaveUrlDrawerHandler = () => {
        closeSharedUrlDrawer();
        loadShares();
        setSharedUrlIdInEdit(null);
    }

    const onCloseSharedUrlDrawerHandler = () => {
        closeSharedUrlDrawer();
    }

    const closeSharedUrlDrawer = () => {
        setSharedUrlIdInEdit(null);
        setVisibleSharedUrlDrawer(false);
    }

    return (
        <>
            <PageHeader
                className={'site-layout-sub-header-background'}
                ghost={false}
                onBack={() => window.history.back()}
                title="Urls suivis"
                subTitle="Retrouvez ici l'historique des urls que vous suivez"
                extra={[
                    <Button shape="circle" icon={<PlusCircleOutlined/>} type="primary"
                            onClick={onAddSharedUrlHandler}></Button>
                ]}
            >
                <Table
                    columns={columns}
                    dataSource={data}
                />

                <SharedUrlDrawer visible={visibleSharedUrlDrawer} sharedUrlId={sharedUrlIdInEdit}
                                 onSave={onSaveUrlDrawerHandler}
                                 onClose={onCloseSharedUrlDrawerHandler}/>
            </PageHeader>
        </>
    )
}


/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function SharedUrlDrawer(props) {

    const [form] = Form.useForm();
    const [contactOptions, setContactOptions] = useState([]);
    const [selectedContactId, setSelectedContactId] = useState([]);
    const [showContactModal, setShowContactModal] = useState(false);
    const [quota, setQuota] = useState(null);

    useEffect(() => {
        form.resetFields();
        if (props.sharedDocumentId) {
            SharedDocument.getOne(props.sharedDocumentId).then(document => {

            });
        }
    }, [props.sharedDocumentId]);

    useEffect(() => {
        if (props.visible) {
            Quota.getAvailableQuota().then(data => {
                setQuota(data);
            });
        }
    }, [props.visible]);

    const onSaveDrawerHandler = () => {
        let payload = form.getFieldsValue();
        payload.contact_id = selectedContactId;

        if (!props.sharedUrlId || props.sharedUrlId === null) {
            SharedUrl.create(payload).then(() => {
                props.onSave();
            });
        } else {
            SharedUrl.update(props.sharedUrlId, payload).then(() => {
                props.onSave();
            });
        }
    }

    const title = () => {
        if (props.sharedUrlId === null) {
            return "Partage d'une url";
        } else {
            return "Édition d'un partage";
        }
    }

    const defineBestContactLabel = (contact) => {
        if (contact.firstname !== null && contact.lastname !== null) {
            return {
                label: contact.firstname + " " + contact.lastname,
                value: contact.firstname + " " + contact.lastname,
                key: contact._id,
            }
        } else if (contact.email !== null) {
            return {
                label: contact.email,
                value: contact.email,
                key: contact._id,
            }
        } else if (contact.mobile_phone !== null) {
            return {
                label: contact.mobile_phone,
                value: contact.mobile_phone,
                key: contact._id,
            }
        }

        return {
            label: null,
            value: null,
            key: contact._id,
        };
    }

    const onSearchContactHandler = (searchText) => {
        Contact.getAll({
            q: {
                'fulltext': searchText
            }
        }).then((json) => {
            let options = json.map(defineBestContactLabel)
            setContactOptions(
                !searchText ? [] : options
            );
        })
    };

    const onSelectContactHandler = (value, option) => {
        setSelectedContactId(option.key);
    };

    const onSaveContactModalHandler = (contact) => {
        setShowContactModal(false);
        setSelectedContactId(contact._id)
        form.setFieldsValue({
            contact_id: defineBestContactLabel(contact)
        })
    }

    const onCloseContactModalHandler = () => {
        setShowContactModal(false)
    }

    const onClickAddNewContactHandler = () => {
        setShowContactModal(true);
    }

    let content = null;
    let quotaExceeded = (quota && quota.current <= 0);

    if (quotaExceeded) {
        content = (
            <Result
                status="success"
                title="Votre activité est à son max !"
                subTitle="Vous avez utilisé tout votre quota de lien pour ce mois-ci. Vous ne pouvez plus en créer de nouveaux."
                extra={[
                    <Link to={'/billing'}>
                        <Button type="primary" key="console">
                            Il est temps de changer de plan !
                        </Button>
                    </Link>
                ]}
            />
        )
    } else {
        content = (
            <Form
                form={form}
                layout="vertical"
            >
                <Form.Item label="Url à partager" required>
                    <Row gutter={8}>
                        <Col span={24}>

                            <Form.Item name="url">
                                <Input size="large" placeholder="Url : http://google.fr"/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item label="Contact à qui partager l'url" required>
                    <Row gutter={8}>
                        <Col span={22}>
                            <Form.Item name="contact_id" required>
                                <AutoComplete
                                    options={contactOptions}
                                    onSelect={onSelectContactHandler}
                                    onSearch={onSearchContactHandler}
                                >
                                    <Input.Search size="large" placeholder="Chercher ..."/>
                                </AutoComplete>
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Button icon={<PlusOutlined/>} size="large"
                                    onClick={onClickAddNewContactHandler}/>
                        </Col>
                    </Row>
                </Form.Item>
            </Form>
        )
    }

    return (
        <>
            <Drawer title={title()} placement="left" onClose={props.onClose}
                    visible={props.visible} width={700} extra={
                <Space>
                    <Button onClick={props.onClose}>Annuler</Button>
                    <Button onClick={onSaveDrawerHandler} type="primary">
                        Générer le lien
                    </Button>
                </Space>
            }>
                {content}
                <ContactModal open={showContactModal} onSave={onSaveContactModalHandler}
                              onClose={onCloseContactModalHandler}></ContactModal>
            </Drawer>
        </>
    )
}

export default SharedUrlsPage;

