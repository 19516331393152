import {jsonFetch} from "./JsonFetch";

let StatsByDays = {

    /**
     *
     */
    getAll: async (payload) => {
        let url = '/stats_by_days';

        const response = await jsonFetch('POST', url, payload);
        if (response.ok) {
            return response.json();
        }
        throw Error(response.statusText);
    },

}

export {StatsByDays};