import {jsonFetch} from "./JsonFetch";

let Billing = {

    /**
     *
     */
    stripeAcceptModification: async (stripe_price_id) => {
        let url = '/billing/stripe/accept-modification';

        const response = await jsonFetch('POST', url, {stripe_price_id});
        if (response.ok) {
            return response.json();
        }
        throw Error(response.statusText);
    },
    /**
     *
     */
    stripeCreateCheckoutSession: async (stripe_price_id) => {
        let url = '/billing/stripe/create-checkout-session';

        const response = await jsonFetch('POST', url, {stripe_price_id});
        if (response.ok) {
            return response.json();
        }
        throw Error(response.statusText);
    },

    /**
     *
     */
    stripeCreatePortalSession: async () => {
        let url = '/billing/stripe/create-portal-session';

        const response = await jsonFetch('POST', url, {});
        if (response.ok) {
            return response.json();
        }
        throw Error(response.statusText);
    },

    /**
     *
     */
    stripeCancelSubscription: async () => {
        let url = '/billing/stripe/cancel-subscription';

        const response = await jsonFetch('PUT', url, {});
        if (response.ok) {
            return response.json();
        }
        throw Error(response.statusText);
    },

    /**
     *
     */
    getCurrentSubscription: async () => {
        let url = '/billing/subscription';

        const response = await jsonFetch('GET', url);
        if (response.ok) {
            return response.json();
        }
        throw Error(response.statusText);
    },
}

export {Billing};
