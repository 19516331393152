import {
    Button,
    Drawer,
    Form,
    Input,
    message,
    Modal,
    notification,
    Progress,
    Select,
    Space,
    Spin,
    Table,
    Upload
} from "antd";
import React, {useEffect, useState} from "react"

import {EditOutlined, EyeOutlined, InboxOutlined, PlusCircleOutlined, FileOutlined} from '@ant-design/icons';
import {Config} from "../config";
import {Document} from "../api/Document";
import {DateTime} from "luxon";
import {DocumentCategory} from "../api/DocumentCategory";
import {PageHeader} from "@ant-design/pro-components";

const {Dragger} = Upload;

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
function DocumentsPage() {

    const [visibleDocumentDrawer, setVisibleDocumentDrawer] = useState(false);
    const [documentIdInEdit, setDocumentIdInEdit] = useState(null);
    const [data, setData] = useState(false);

    const columns = [
        {
            title: 'Nom',
            dataIndex: 'name'
        },
        {
            title: 'Date de création',
            width: '180px',
            render: (row) => (<>
                {DateTime.fromISO(row.created_at).toLocaleString(DateTime.DATETIME_SHORT)}
            </>)
        },
        {
            width: '110px',
            render: (row) => (<Space>
                    <Button shape="circle" icon={<EditOutlined/>} onClick={() => {
                        onEditDocumentHandler(row.id)
                    }}/>
                    <Button shape="circle" icon={<EyeOutlined/>} onClick={() => {
                        onViewSharedDocumentHandler(row.public_id)
                    }}/>
                    </Space>
            ),
        },
    ];

    useEffect(() => {
        loadDocuments();
    }, []);

    const onViewSharedDocumentHandler = (document_public_id) => {
        window.open(Config.prodokLink + '?metaf=' + document_public_id, '_blank')
    }

    const loadDocuments = () => {
        Document.getAll({}).then((json) => {
            setData(json);
        });
    }

    const onAddDocumentHandler = () => {
        setVisibleDocumentDrawer(true);
    }

    const onEditDocumentHandler = (documentId) => {
        setDocumentIdInEdit(documentId);
        setVisibleDocumentDrawer(true);
    }

    const onDeleteDocumentHandler = (documentId) => {
        Document.delete(documentId).then(() => {
            loadDocuments();
        });
    }

    const onSaveDocumentDrawerHandler = () => {
        closeDocumentDrawer();
        loadDocuments();
    }

    const onCloseDocumentDrawerHandler = () => {
        closeDocumentDrawer();
    }

    const closeDocumentDrawer = () => {
        setDocumentIdInEdit(null);
        setVisibleDocumentDrawer(false);
    }

    return (
        <>
            <PageHeader
                className={'site-layout-sub-header-background'}
                ghost={false}
                onBack={() => window.history.back()}
                title="Documents"
                subTitle="Retrouvez ici votre base documentaire"
                extra={[
                    <Button shape="circle" icon={<PlusCircleOutlined/>} type="primary"
                            onClick={onAddDocumentHandler}></Button>
                ]}
            >
                <Table
                    columns={columns}
                    dataSource={data}
                />

                <DocumentDrawer visible={visibleDocumentDrawer} tagId={documentIdInEdit}
                                onSave={onSaveDocumentDrawerHandler} onClose={onCloseDocumentDrawerHandler}/>
            </PageHeader>
        </>
    )
}

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function DocumentDrawer(props) {

    const [form] = Form.useForm();
    const [documentInEdit, setDocumentInEdit] = useState(null);
    const [lastUploadedFile, setLastUploadedFile] = useState(null);
    const [documentCategories, setDocumentCategories] = useState([]);
    const [currentCategoryForm, setCurrentCategoryForm] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [uploadingPercent, setUploadingPercent] = useState(false);

    useEffect(async () => {
        form.resetFields();
        setDocumentInEdit(null);
        setLastUploadedFile(null);

        if (props.visible) {
            await DocumentCategory.getAll({}).then(categories => setDocumentCategories(categories));
        }

        if (props.tagId) {
            Document.getOne(props.tagId).then(document => {
                setDocumentInEdit(document);

                let category_id = null;
                documentCategories.forEach((category) => {
                    if (document.category_id == category.id) {
                        category_id = category.id
                    }
                })

                form.setFieldsValue({
                    name: document.name,
                    category_id: category_id
                })
            });
        }
    }, [props.visible, props.tagId])

    const onSaveDrawerHandler = () => {
        let payload = form.getFieldsValue();

        if (payload.category_id != null) {
            payload.category_id = parseInt(payload.category_id)
        } else {
            payload.category_id = null;
        }

        if (props.tagId === null) {

            payload.original_filename = lastUploadedFile.name;
            payload.public_id = lastUploadedFile.response.public_id;

            Document.create(payload).then(() => {
                props.onSave();
                notification.success({
                    message: 'Le document a bien été créé.'
                })
            });
        } else {
            Document.update(props.tagId, payload).then(() => {
                props.onSave();
                notification.success({
                    message: 'Le document a bien été mis à jour.'
                })
            });
        }
    }

    const title = () => {
        if (props.tagId === null) {
            return "Ajout d'un nouveau document";
        } else {
            return "Édition d'un document";
        }
    }

    const dragger = () => {

        if (props.tagId !== null) {
            return;
        }

        const uploadProps = {
            name: 'file',
            multiple: false,
            showUploadList: false,
            action: Config.apiUrl + '/document/upload',
            onChange(info) {
                console.log(info.file);
                const {status} = info.file;

                if (status !== 'uploading') {
                    setLastUploadedFile(info.file)
                }else {
                    setUploading(true);
                    setUploadingPercent(Math.round(info.file.percent));
                }

                if (status === 'done') {
                    setUploading(false);
                    message.success(`${info.file.name} file uploaded successfully.`);
                } else if (status === 'error') {
                    setUploading(false);
                    message.error(`${info.file.name} file upload failed.`);
                }
            },

            onDrop(e) {
                console.log('Dropped files', e.dataTransfer.files);
            },
        };

        let draggerMessage = (
            <>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined/>
                </p>
                <p className="ant-upload-text">Cliquez ou déplacez <br/>le fichier .pdf dans cette zone</p>
            </>
        )

        if (uploading) {
            draggerMessage = (
                <>
                    <Progress type="circle" percent={uploadingPercent} size={80} />
                </>
            )
        } else if (lastUploadedFile !== null) {
            draggerMessage = (
                <>
                    <p className="ant-upload-drag-icon">
                        <FileOutlined />
                    </p>
                    <p className="ant-upload-text">{lastUploadedFile.name}</p>
                </>
            )
        }

        return (
            <div style={{
                "height": "300px",
                "marginBottom": "20px"
            }}>
                <Dragger {...uploadProps}>
                    {draggerMessage}
                </Dragger>
            </div>
        )
    }

    return (
        <>
            <Drawer title={title()} placement="left" onClose={props.onClose}
                    visible={props.visible} width={700} extra={
                <Space>
                    <Button onClick={props.onClose}>Annuler</Button>
                    <Button onClick={onSaveDrawerHandler} type="primary">
                        Enregistrer
                    </Button>
                </Space>
            }>
                {dragger()}
                <Form
                    form={form}
                    layout="vertical"
                >
                    <Form.Item name="name" label="Nom" required tooltip="This is a required field">
                        <Input placeholder="ex : présentation de l'offre"/>
                    </Form.Item>
                    <Form.Item name="category_id" label="Catégorie">
                        <Select
                            fieldNames={{label: 'name', value: 'id'}}
                            allowClear
                            style={{width: '100%'}}
                            disabled={documentCategories.length == 0}
                            options={documentCategories}
                        >
                        </Select>
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    )
}

export default DocumentsPage;

