import React, {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";

import {Oauth2} from "../../api/Oauth2";
import {Button, Form, Input, Layout, notification} from "antd";
import {LockOutlined, UserOutlined} from "@ant-design/icons";

import LayoutPage from "../login/LayoutIdentificationPages";
import ory from "../../helpers/ory";

const {Content} = Layout;

const OAuthLogin = () => {
    const [loading, setLoading] = useState(false);
    const [formLogin] = Form.useForm();
    const [flow, setFlow] = useState()
    const [showLogin, setShowLogin] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();

    let challenge = searchParams.get("login_challenge")

    useEffect(() => {

        if (challenge) {
            Oauth2.loginChallenge(challenge).then((data) => {
                if (data.redirect_to) {
                    window.location = data.redirect_to;
                }

                setShowLogin(!data.skip)
            })
        }

        ory
            .toSession()
            .then(({data}) => {
                // connected
            })
            .catch(() => {
                ory
                    .initializeSelfServiceLoginFlowForBrowsers(true, undefined, undefined)
                    .then(({data}) => {
                        setFlow(data)
                    })
            })
    }, [])

    const onSubmit = (values) => {
        ory.submitSelfServiceLoginFlow(flow.id, {
            identifier: values['username'],
            method: "password",
            password: values['password'],
            csrf_token: flow.ui.nodes[0].attributes.value
        }).then(() => {
            Oauth2.loginChallenge(challenge).then((data) => {
                if (data.redirect_to) {
                    window.location = data.redirect_to;
                }

                setShowLogin(!data.skip)
            })
        }).catch(() => {
            notification['error']({
                message: "L'authentification a échouée"
            });
        }).finally(() => {
            setLoading(false);
        });
    }

    const onFinish = () => {
        let values = formLogin.getFieldsValue();
        onSubmit(values)
    }

    if (!showLogin) {
        return 'connected';
    }

    return (
        <LayoutPage title="Identification">
            <Content>
                <Form
                    form={formLogin}
                    name="normal_login"
                    className="login-form"
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: "Le nom d'utilisateur ne peut pas être vide",
                            },
                        ]}
                    >
                        <Input
                            prefix={<UserOutlined className="site-form-item-icon"/>}
                            placeholder="Nom d'utilisateur"
                        />
                    </Form.Item>

                    <Form.Item
                        className="mb-2"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: "Le password ne peut pas être vide",
                            },
                        ]}
                    >
                        <Input
                            prefix={<LockOutlined className="site-form-item-icon"/>}
                            type="password"
                            placeholder="Mot de passe"
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button" loading={loading}>
                            Connexion
                        </Button>
                    </Form.Item>
                </Form>
            </Content>
        </LayoutPage>
    )
}

export default OAuthLogin;
