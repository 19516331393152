import {
    Card,
    Col,
    Collapse,
    Empty,
    Form,
    Input,
    List,
    notification,
    Progress,
    Row,
    Select,
    Skeleton,
    Spin,
    Tag,
    Timeline,
    Typography
} from "antd";
import React, {useEffect, useState} from "react"
import {Contact} from "../api/Contact";
import {
    BookOutlined,
    ClockCircleOutlined,
    FieldNumberOutlined,
    FireOutlined,
    LinkOutlined,
    MailOutlined
} from "@ant-design/icons";
import Modal from "react-responsive-modal";
import {ContactTimeline} from "../api/ContactTimeline";
import {Tag as ApiTag} from "../api/Tag";
import {DateTime} from "luxon";
import {Config} from "../config";

import 'simplebar/dist/simplebar.min.css';
import SimpleBar from "simplebar-react";

const {Text} = Typography;
const {Option} = Select;
const {Panel} = Collapse;

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function ContactModal(props) {
    const [timeline, setTimeline] = useState([]);
    const [loadingTimeline, setLoadingTimeline] = useState(false);
    const [contact, setContact] = useState(null);
    const [tags, setTags] = useState([]);
    const [form] = Form.useForm();

    useEffect(async () => {
        form.resetFields();
        setTimeline([]);
        setContact(null);

        if (props.contactId) {
            setLoadingTimeline(true);
        }

        await ApiTag.getAll({}).then(tags => setTags(tags));

        if (props.contactId) {
            ContactTimeline.getOne(props.contactId).then((data) => {
                setTimeline(data)
                setLoadingTimeline(false);
            });

            Contact.getOne(props.contactId).then(contact => {
                setContact(contact);

                let tagsForm = [];
                tags.forEach((tag) => {
                    if (contact.tags.indexOf(tag.id) > -1) {
                        console.log('push')
                        tagsForm.push({
                            'key': tag.id,
                            'label': tag.name
                        })
                    }
                })

                form.setFieldsValue({
                    firstname: contact.firstname,
                    lastname: contact.lastname,
                    email: contact.email,
                    phone: contact.phone,
                    mobile_phone: contact.mobile_phone,
                    company: contact.company,
                    website: contact.website,
                    address: contact.address,
                    office: contact.office,
                    tags: tagsForm
                })
            });
        }
    }, [props.open])

    /**
     *
     */
    const onSaveDrawerHandler = () => {
        let payload = form.getFieldsValue();

        if ('tags' in payload && payload['tags']) {
            payload['tags'] = payload['tags'].map(tag => parseInt(tag))
        }

        if (!props.contactId || props.contactId === null) {
            Contact.create(payload).then((contact) => {
                props.onSave(contact);
                notification.success({
                    'message': 'Le contact a bien été créé.'
                })
            });
        } else {
            Contact.update(props.contactId, payload).then((contact) => {
                props.onSave(contact);
                notification.success({
                    'message': 'Le contact a bien été mis à jour.'
                })
            });
        }
    }

    // timeline

    let componentsTimeline = [];
    timeline.forEach((event, idx) => {

        let firstEvent = null;
        if (idx === 0) {
            firstEvent = (
                <Tag style={{fontSize: "10px"}} color="processing">
                    Évènement le plus récent
                </Tag>
            );
        }

        if (event['type'] == "document_load") {

            let subComponentEvents = [];
            event.timeline.forEach((subevent) => {

                if (subevent['type'] == 'document_page_read') {
                    let progression = 0;
                    let read_progression = 0;
                    let progressionText = null;

                    if (subevent.meta.read_progression) {
                        if (subevent.meta.read_progression >= 1) {
                            read_progression = 1;
                        } else {
                            read_progression = subevent.meta.read_progression;
                        }

                        progression = (read_progression * 100).toFixed(2);
                    }

                    progressionText = (<>de <Text code>{progression}%</Text></>)

                    subComponentEvents.push(<li position={'left'}>Lecture {progressionText} de la
                        page <Text code>{subevent.meta.page}/{subevent.meta.total_page}</Text> pendant <Text
                            code>{Math.round(subevent.meta.duration)}s</Text></li>)
                }
            })

            let subComponent = null;
            if (subComponentEvents.length > 0) {
                subComponent = (
                    <div style={{
                        padding: "10px",
                        backgroundColor: "rgba(0, 0, 0, 0.02)",
                        borderRadius: "8px",
                        fontSize: "14px"
                    }}>
                        <ul>
                            {subComponentEvents}
                        </ul>
                    </div>
                )
            }

            let readScore = (<Tag color={'default'}>Glacial</Tag>)

            if (event.meta.read_score == 1) {
                readScore = (<Tag color={'blue'}>Froid</Tag>)
            } else if (event.meta.read_score == 2) {
                readScore = (<Tag color={'green'}>Tiède</Tag>)
            } else if (event.meta.read_score == 3) {
                readScore = (<Tag color={'gold'}>Chaud</Tag>)
            } else if (event.meta.read_score == 4) {
                readScore = (<Tag color={'red'} icon={<FireOutlined/>}>Brûlant</Tag>)
            }

            let collapseDefaultActiveKey = (idx > 1) ? [] : [1]

            componentsTimeline.push((
                <Timeline.Item dot={<BookOutlined style={{fontSize: '18px'}}/>} style={{fontSize: '16px'}}
                               color="green">
                    <p style={{marginBottom: "5px", fontSize: "8px"}}>{firstEvent}</p>
                    <Row>
                        <Col flex="auto">Lecture du document <a onClick={() => {
                            window.open(Config.prodokLink + '?metaf=' + event.meta.document.public_id, '_blank')
                        }}>{event.meta.document.name}</a></Col>
                        <Col flex="100px" style={{textAlign: "right"}}>{readScore}</Col>
                    </Row>
                    <p style={{fontSize: "12px"}}>à {DateTime.fromISO(event.received_at).toLocaleString(DateTime.DATETIME_SHORT)} </p>
                    {(event.meta.read_progression) ? (
                        <div><Progress percent={Math.round(event.meta.read_progression * 100)} size="small"/>
                        </div>) : null}
                    <p></p>
                    <Collapse defaultActiveKey={collapseDefaultActiveKey} ghost>
                        <Panel header="Voi les détails" key="1">
                            {subComponent}
                        </Panel>
                    </Collapse>
                </Timeline.Item>
            ))
        } else if (event['type'] == "pixel") {
            componentsTimeline.push((
                <Timeline.Item dot={<MailOutlined style={{fontSize: '18px'}}/>} style={{fontSize: '16px'}}
                               color="green">
                    <p style={{marginBottom: "5px", fontSize: "8px"}}>{firstEvent}</p>
                    <p>Lecture d'email</p>
                    <p style={{fontSize: "12px"}}>à {DateTime.fromISO(event.received_at).toLocaleString(DateTime.DATETIME_SHORT)} </p>
                </Timeline.Item>
            ))
        } else if (event['type'] == "url_redirection") {
            componentsTimeline.push((
                <Timeline.Item dot={<LinkOutlined style={{fontSize: '18px'}}/>} style={{fontSize: '16px'}}
                               color="green">
                    <p style={{marginBottom: "5px", fontSize: "8px"}}>{firstEvent}</p>
                    <p>Click sur le lien <a target={"_blank"} href={event['meta']['url']}>{event['meta']['url']}</a></p>
                    <p style={{fontSize: "12px"}}>à {DateTime.fromISO(event.received_at).toLocaleString(DateTime.DATETIME_SHORT)} </p>
                </Timeline.Item>
            ))
        }
    })

    let componentTimeline = (
        <div style={{
            textAlign: "center",
            padding: "25px"
        }}>
            <Spin size="large"/>
        </div>
    )

    if (!loadingTimeline) {
        if (timeline.length > 0) {
            componentTimeline = (
                <Timeline>
                    {componentsTimeline}
                </Timeline>
            )
        } else {
            componentTimeline = (
                <Empty
                    imageStyle={{
                        height: 60,
                    }}
                    description={<span> Aucun évènement </span>}>
                </Empty>
            )
        }
    }

    let behaviourList = [];

    if (contact !== null) {
        behaviourList = [{
            avatar: (<ClockCircleOutlined/>),
            title: 'Date du dernier évènement',
            description: (contact.behaviour && contact.behaviour.last_event_at) ? DateTime.fromISO(contact.behaviour.last_event_at).toLocaleString(DateTime.DATETIME_SHORT) : null,
        }, {
            avatar: (<ClockCircleOutlined/>),
            title: 'Date de création',
            description: DateTime.fromISO(contact.created_at).toLocaleString(DateTime.DATETIME_SHORT),
        }]
    }

    let countsList = [];

    if (contact !== null) {
        countsList = [{
            avatar: (<FieldNumberOutlined/>),
            title: 'Nombre de documents ouverts',
            description: (contact.counts && contact.counts.opened_documents) ? contact.counts.opened_documents : null,
        }]
    }

    return (
        <Modal open={props.open} onClose={props.onClose} showCloseIcon={true} center
               classNames={{
                   overlay: 'customOverlay',
                   modal: 'customModal',
               }}>
            <div style={{"height": "100%"}}>

                <div style={{"height": "100%"}}>
                    <Row style={{"height": "100%"}} gutter={10}>
                        <Col style={{"height": "100%"}} span={8}>
                            <SimpleBar style={{height: "100%"}}>
                                <Card title="Informations"
                                      style={{marginBottom: "10px"}}
                                      extra={<a href="#" onClick={onSaveDrawerHandler}>Enregistrer</a>}>
                                    <Form
                                        form={form}
                                        layout="vertical"
                                    >
                                        <Form.Item name="firstname" label="Prénom" required
                                                   tooltip="This is a required field">
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item name="lastname" label="Nom" required
                                                   tooltip="This is a required field">
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item name="email" label="Email" required
                                                   tooltip="This is a required field">
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item name="phone" label="Téléphone fixe">
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item name="mobile_phone" label="Téléphone mobile">
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item name="company" label="Entreprise">
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item name="office" label="Fonction">
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item name="website" label="Site internet">
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item name="address" label="Adresse physique">
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item name="tags" label="Tags">
                                            <Select
                                                mode="multiple"
                                                allowClear
                                                style={{width: '100%'}}
                                                placeholder="Please select"
                                            >
                                                {tags.map(tag => {
                                                    return (<Option key={tag.id}>{tag.name}</Option>)
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Form>
                                </Card>
                                <Card title="Comportement" style={{marginBottom: "10px"}}>
                                    <List
                                        className="demo-loadmore-list"
                                        itemLayout="horizontal"
                                        dataSource={behaviourList}
                                        renderItem={(item) => (
                                            <List.Item>
                                                <Skeleton avatar title={false} loading={item.loading} active>
                                                    <List.Item.Meta
                                                        avatar={item.avatar}
                                                        title={item.title}
                                                        description={item.description}
                                                    />
                                                    <div>{item.content}</div>
                                                </Skeleton>
                                            </List.Item>
                                        )}
                                    />
                                </Card>
                                <Card title="Statistiques" style={{marginBottom: "7px"}}>
                                    <List
                                        className="demo-loadmore-list"
                                        itemLayout="horizontal"
                                        dataSource={countsList}
                                        renderItem={(item) => (
                                            <List.Item>
                                                <Skeleton avatar title={false} loading={item.loading} active>
                                                    <List.Item.Meta
                                                        avatar={item.avatar}
                                                        title={item.title}
                                                        description={item.description}
                                                    />
                                                    <div>{item.content}</div>
                                                </Skeleton>
                                            </List.Item>
                                        )}
                                    />
                                </Card>
                            </SimpleBar>
                        </Col>
                        <Col style={{"height": "100%"}} span={16}>
                            <SimpleBar style={{height: "100%"}}>
                                <Card style={{"height": "100%", overflow: "hidden"}}
                                      bodyStyle={{overflow: "hidden"}}>
                                    {componentTimeline}
                                </Card>
                            </SimpleBar>
                        </Col>
                    </Row>
                </div>
            </div>
        </Modal>
    )
}

export default ContactModal;

