import {Config} from "../config";

let headers = new Headers({
	'Accept': 'application/json',
	'Content-Type': 'application/json'
});

export async function jsonFetch (method, route_name, body_raw) {
	try {
		let response;

		if (method == 'POST') {
			response = HttpPostRequest(route_name, body_raw);
		} else if (method == 'PUT') {
			response = HttpPutRequest(route_name, body_raw);
		} else if (method == 'GET') {
			response = HttpGetRequest(route_name);
		} else if (method == 'DELETE') {
			response = HttpDeleteRequest(route_name, body_raw);
		}

		return (await response);
	} catch (error) {}
}

/**
 * [HttpPostRequest description]
 * @param {[type]} route_name [description]
 * @param {[type]} body_raw   [description]
 */
export async function HttpPostRequest (route_name, body_raw) {
	try {
		const response = await
			fetch(Config.apiUrl + route_name, {
				method: 'POST',
				credentials: 'include',
				headers,
				body: JSON.stringify(body_raw)
			});

		return (await response);
	} catch (error) {}
}

/**
 * [HttpGetRequest description]
 * @param {[type]} route_name [description]
 */
export async function HttpGetRequest (route_name) {
	try {
		const response = await
			fetch(Config.apiUrl + route_name, {
				method: 'GET',
				credentials: 'include',
				headers,
			});

		return (await response);
	} catch (error) {}
}


/**
 * [HttpDeleteRequest description]
 * @param {[type]} route_name [description]
 */
export async function HttpDeleteRequest (route_name, body_raw) {
	try {
		const response = await
			fetch(Config.apiUrl + route_name, {
				method: 'DELETE',
				credentials: 'include',
				headers,
				body: JSON.stringify(body_raw)
			});

		return (await response);
	} catch (error) {}
}

/**
[HttpPutRequest description]
@param {[type]} route_name [description]
@param {[type]} body_raw   [description]
*/
export async function HttpPutRequest (route_name, body_raw) {
 	try {
	 	const response = await
     		fetch(Config.apiUrl + route_name, {
         		method: 'PUT',
				credentials: 'include',
         		headers,
         		body: JSON.stringify(body_raw)
     		});
 		return (await response);
	} catch (error) {}
}