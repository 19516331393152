import {Card, Col, Row, Statistic, Tooltip} from "antd";
import React, {useEffect, useState} from "react"
import {StatsByDays} from "../api/StatsByDays";
import {DateTime} from "luxon";
import {Bar, BarChart, Line, LineChart, ResponsiveContainer, Tooltip as RechartTooltip, XAxis, YAxis} from 'recharts';
import {jsonFetch} from "../api/JsonFetch";
import {QuestionCircleOutlined} from "@ant-design/icons";
import {Profile} from "../api/Profile";
import {PageHeader} from "@ant-design/pro-components";


/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
function Home() {

    const intervalDayNumber = 7;

    const [data, setData] = useState([]);
    const [profile, setProfile] = useState(null);
    const [globalStats, setGlobalStats] = useState({});
    const [averageDuration, setAverageDuration] = useState(0);
    const [totalDocumentLoad, setTotalDocumentLoad] = useState(0);

    let startDate = DateTime.now().setZone("Europe/Paris").minus({days: 6}).startOf('days').setZone("utc");
    let endDate = DateTime.now().setZone("Europe/Paris").endOf('days').setZone("utc");

    // Second, gather session data, if the user is not logged in, redirect to login
    useEffect(async () => {
        loadProfile();

        StatsByDays.getAll({
            'start_date': startDate,
            'end_date': endDate
        }).then((resp) => {
            let filledStats = []
            let cursorStartDate = startDate;
            while (cursorStartDate < endDate) {
                filledStats.push({
                    'duration': 0,
                    'document_load': 0,
                    'start_date': DateTime.fromISO(cursorStartDate.toISO(), {zone: 'utc'}),
                    'formatted_start_date': DateTime.fromISO(cursorStartDate.toISO(), {zone: 'utc'}).setZone("Europe/Paris").toFormat('dd/MM/yyyy HH:mm')
                })

                cursorStartDate = cursorStartDate.plus({hours: 1});
            }

            let formattedData = filledStats.map((item) => {
                resp.forEach((r) => {
                    if (item['start_date'].equals(DateTime.fromISO(r.start_date, {zone: 'utc'}))) {
                        item.duration = r.duration;
                        item.document_load = r.document_load;
                    }
                })

                return item;
            });

            setData(formattedData)

            let totalDuration = 0;
            let totalDocumentLoad = 0;
            resp.forEach((r) => {
                totalDuration += r['duration']
                totalDocumentLoad += r['document_load']
            })

            if (totalDocumentLoad) {
                setAverageDuration(totalDuration / totalDocumentLoad);
                setTotalDocumentLoad(totalDocumentLoad);
            }
        })

        const response = await jsonFetch('GET', '/stats/global');
        if (response.ok) {
            setGlobalStats(await response.json());
        }
    }, [])


    let loadProfile = () => {
        return Profile.me().then(profile => {
            setProfile(profile);
        });
    }

    const renderDurationChart = (
        <ResponsiveContainer width={'100%'} height={300}>
            <BarChart syncId="anyId" data={data} margin={{top: 5, right: 20, bottom: 5, left: 0}}>
                <Bar dataKey="duration" barSize={30} fill="#8884d8"/>
                <XAxis dataKey="formatted_start_date"/>
                <YAxis/>
                <RechartTooltip/>
            </BarChart>
        </ResponsiveContainer>
    );

    const renderDocumentLoadChart = (
        <ResponsiveContainer width={'100%'} height={300}>
            <LineChart syncId="anyId" data={data} margin={{top: 5, right: 20, bottom: 5, left: 0}}>
                <Line dataKey="document_load" fill="#8884d8"/>
                <XAxis dataKey="formatted_start_date"/>
                <YAxis/>
                <RechartTooltip/>
            </LineChart>
        </ResponsiveContainer>
    );

    if (profile === null) {
        return (<></>);
    }

    return (
        <>

            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <PageHeader
                        ghost={false}
                        title={"Bienvenue " + profile?.firstname + " " + profile?.lastname + ","}
                        extra={[]}
                    >
                    </PageHeader>
                </Col>

                <Col span={6}>
                    <Card>
                        <Statistic
                            title={<>Nombre de contacts</>}
                            value={globalStats.total_contacts}
                            precision={0}
                            valueStyle={{color: '#3f8600'}}
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic
                            title="Nombre de documents"
                            value={globalStats.total_documents}
                            precision={0}
                            valueStyle={{color: '#cf1322'}}
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic
                            title={<>Temps de lecture moyenne <Tooltip title="Sur les 7 derniers jours"><QuestionCircleOutlined /></Tooltip></>}
                            value={averageDuration}
                            precision={0}
                            valueStyle={{color: '#cf1322'}}
                            suffix={'secondes'}
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic
                            title={<>Nombre de documents ouverts <Tooltip title="Sur les 7 derniers jours"><QuestionCircleOutlined /></Tooltip></>}
                            value={totalDocumentLoad}
                            precision={0}
                            valueStyle={{color: '#cf1322'}}
                        />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card title={<>Temps de lecture <Tooltip title={<>Sur les {intervalDayNumber} derniers jours</>}><QuestionCircleOutlined /></Tooltip></>}>
                        {renderDurationChart}
                    </Card>
                </Col>
                <Col span={12}>
                    <Card title={<>Nombre de documents ouverts <Tooltip title={<>Sur les {intervalDayNumber} derniers jours</>}><QuestionCircleOutlined /></Tooltip></>}>
                        {renderDocumentLoadChart}
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default Home;

