import '../../Register.css';

import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";

import {Button, Col, Divider, Form, Image, Input, Layout, notification, Result, Row, Space} from "antd";
import {LockOutlined} from "@ant-design/icons";

import ory from "../../helpers/ory";
import logo from '../../assets/Skani.png';
import Title from "antd/es/typography/Title";
import {Profile} from "../../api/Profile";
import {Company} from "../../api/Company";

const {Content} = Layout;


const Register = () => {
    const [showSuccess, setShowSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [flow, setFlow] = useState()

    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const [formLogin] = Form.useForm();

    // Second, gather session data, if the user is not logged in, redirect to login
    useEffect(() => {
        ory
            .toSession()
            .then(({data}) => {
                navigate('/');
            })
            .catch(() => {
                ory
                    .initializeSelfServiceRegistrationFlowForBrowsers()
                    .then(({data}) => {
                        setFlow(data)
                        let urlSearchParams = new URLSearchParams();
                        urlSearchParams.set('flow', data?.id);

                        if (searchParams.has('sponsor')) {
                            urlSearchParams.set('sponsor', searchParams.get('sponsor'));
                        }

                        navigate(`/register?${urlSearchParams.toString()}`, undefined, {shallow: true})
                    })
            })
    }, [])

    const onSubmit = (values) => {

        if (values['password'] !== values['password-confirm']) {
            notification['error']({
                message: "Le mot de passe et sa confirmation ne correspondent pas."
            });

            return;
        }

        setLoading(true);

        ory.submitSelfServiceRegistrationFlow(flow.id, {
            traits: {
                email: values['email']
            },
            method: "password",
            password: values['password'],
            csrf_token: flow.ui.nodes[0].attributes.value
        }).then(async (data) => {
            let company = await Company.create({'name': values['company']})
            let profile = await Profile.create({
                user_id: data.data.identity.id,
                company_id: company.id,
                email: values['email'],
                firstname: values['firstname'],
                lastname: values['lastname'],
                from_sponsor_id: searchParams.get('sponsor')
            })

            setShowSuccess(true)
        }).catch((data, data2) => {
            for (let node of data.response.data.ui.nodes) {
                if (node.attributes.type == 'password' && node.messages.length > 0) {
                    notification['error']({
                        message: "L'inscription a échoué",
                        description: (<div>
                            Le password a été refusé pour une des raisons suivantes :
                            <ul>
                                <li>Tous les critères ne sont pas respectés</li>
                                <li>Vous utilisez des mots simples ou trop communs</li>
                            </ul>
                        </div>)
                    });
                    break;
                }
            }

            notification['error']({
                message: "L'inscription a échoué",
                description: "L'email que vous avez saisie est peut être déjà utilisée."
            });
        }).finally(() => {
            setLoading(false);
        });
    }

    const onFinish = () => {
        let values = formLogin.getFieldsValue();
        onSubmit(values)
    }

    let sidebar = (
        <Form
            style={{
                'maxWidth': '500px',
                marginRight: '50px'
            }}
            form={formLogin}
            name="normal_login"
            className="login-form"
            onFinish={onFinish}
        >
            <Form.Item
                name="email"
                rules={[
                    {
                        required: true,
                        message: "L'email ne peut pas être vide",
                    },
                ]}
            >
                <Input
                    type="email"
                    size={'large'}
                    placeholder="E-mail"
                    required
                />
            </Form.Item>

            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="firstname"
                        rules={[
                            {
                                required: true,
                                message: "Le prénom ne peut pas être vide",
                            },
                        ]}
                    >
                        <Input
                            size={'large'}
                            placeholder="Prénom"
                            required
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="lastname"
                        rules={[
                            {
                                required: true,
                                message: "Le nom ne peut pas être vide",
                            },
                        ]}
                    >
                        <Input
                            size={'large'}
                            placeholder="Nom"
                            required
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Form.Item
                name="company"
                rules={[
                    {
                        required: true,
                        message: "Le nom de votre société ne peut être vide",
                    },
                ]}
            >
                <Input
                    size={'large'}
                    placeholder="Nom de votre société"
                    required
                />
            </Form.Item>

            <Divider/>

            <Form.Item>
                8 caractères minimum, évitez les mots simples et répétitifs.
            </Form.Item>

            <Form.Item
                className="mb-2"
                name="password"
                rules={[
                    {
                        required: true,
                        message: "Le password ne peut pas être vide",
                    },
                ]}
            >
                <Input
                    prefix={<LockOutlined className="site-form-item-icon"/>}
                    size={'large'}
                    type="password"
                    placeholder="Mot de passe"
                    required
                />
            </Form.Item>

            <Form.Item
                className="mb-2"
                name="password-confirm"
                rules={[
                    {
                        required: true,
                        message: "La confirmation ne peut pas être vide",
                    },
                ]}
            >
                <Input
                    prefix={<LockOutlined className="site-form-item-icon"/>}
                    size={'large'}
                    type="password"
                    placeholder="Confirmation du mot de passe"
                    required
                />
            </Form.Item>

            <Divider/>

            <Row>
                <Col span={24}>
                    <Form.Item className="mt-0">
                        <Space><a className="login-form-forgot" href="/login" style={{float: 'right'}}>
                            Déjà inscrit ?
                        </a> ou <a className="login-form-forgot" href="/login/recovery/request"
                                   style={{float: 'right'}}>
                            Mot de pass oublié ?
                        </a></Space>
                    </Form.Item>
                </Col>
            </Row>

            <Form.Item>
                <Button type="primary" htmlType="submit" className="login-form-button"
                        loading={loading}>
                    Je m'inscris
                </Button>
            </Form.Item>
        </Form>
    )

    if (showSuccess) {
        sidebar = (
            <Result
                status="success"
                title="Inscription terminée"
                subTitle="Vous allez recevoir un email pour activer votre compte et vous connecter."
                extra={[
                    <Link to={'/login'}>
                        <Button type="primary" key="console">
                            Connexion
                        </Button>
                    </Link>
                ]}
            />
        )
    }

    return (
        <Layout title="Inscription" style={{
            backgroundColor: "white",
            height: "100vh"
        }}>
            <Content>
                <Row style={{
                    height: '100%'
                }}>
                    <Col span={12} style={{
                        backgroundColor: 'rgb(240, 255, 246)'
                    }}>
                        <div style={{
                            position: "absolute",
                            right: "50px",
                            top: "150px",
                            textAlign: 'right'
                        }}>
                            <Image src={logo} alt="Logo" height={80} preview={false}/>
                            <Title style={{
                                fontWeight: "500"
                            }}>Inscrivez-vous <span style={{
                                fontWeight: '600'
                            }}>GRATUITEMENT</span></Title>
                            <div style={{
                                fontSize: '22px'
                            }}>Et utilisez Skani en quelques secondes !
                            </div>
                        </div>
                    </Col>
                    <Col span={12} style={{
                        'padding': '170px 0 0 50px',
                    }}>
                        {sidebar}
                    </Col>
                </Row>
            </Content>
        </Layout>
    )
}

export default Register;
