import {Button, Col, Form, message, Result, Row, Select, Steps, Table} from "antd";
import React, {useEffect, useState} from "react"
import Modal from "react-responsive-modal";
import {Config} from "../config";
import {InboxOutlined} from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";
import {Option} from "antd/es/mentions";
import {useForm} from "antd/es/form/Form";

const {Step} = Steps;

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function ImportContactModal(props) {
    const [step, setStep] = useState(0);
    const [filePreviewUploding, setFilePreviewUploding] = useState(false);
    const [selectedFileToUpload, setSelectedFileToUpload] = useState(null);
    const [importUploading, setImportUploading] = useState(false);

    const [formMapping] = useForm();

    useEffect(() => {
        reset();
    }, [props.open])

    let reset = () => {
        setStep(0);
        setFilePreviewUploding(false);
        setSelectedFileToUpload(null);
        formMapping.resetFields();
    }

    let onClickPrevStep1Handler = () => {
        setSelectedFileToUpload(null);
        setStep(0)
    }

    let onClickNextStep1Handler = () => {
        setStep(2)
    }

    let onClickPrevStep2Handler = () => {
        setStep(1)
    }

    let onClickNextStep2Handler = () => {
        setStep(3)
    }

    let onClickPrevStep3Handler = () => {
        setStep(2)
    }

    let onImport = () => {
        setImportUploading(true);

        var data = new FormData()
        data.append('file', selectedFileToUpload.originFileObj)
        data.append('delimiter', selectedFileToUpload.response.preview.delimiter)

        data.append('email_key', formMapping.getFieldValue('email'))
        data.append('firstname_key', formMapping.getFieldValue('firstname'))
        data.append('lastname_key', formMapping.getFieldValue('lastname'))
        data.append('phone_key', formMapping.getFieldValue('phone'))
        data.append('mobile_phone_key', formMapping.getFieldValue('mobile_phone'))

        data.append('fieldnames', selectedFileToUpload.response.preview.headers)

        fetch(Config.apiUrl + '/csv/file/import', {
            method: 'POST',
            credentials: 'include',
            body: data
        }).then(() => {
            setImportUploading(false);
            setStep(3);
        }).catch(() => {
            message.error(`file upload failed.`);
        })
    }

    const dragger = () => {

        const uploadProps = {
            name: 'file',
            multiple: false,
            showUploadList: false,
            action: Config.apiUrl + '/csv/file/preview',
            beforeUpload(file) {
                const reader = new FileReader();

                reader.onload = e => {
                    console.log(e.target.result);
                };
                reader.readAsText(file);

            },
            onChange(info) {
                console.log('info', info);
                const {status} = info.file;

                if (status !== 'uploading') {
                    setFilePreviewUploding(false)
                } else {
                    setFilePreviewUploding(true)
                }

                if (status === 'done') {
                    message.success(`${info.file.name} file uploaded successfully.`);
                    setStep(1)
                    setSelectedFileToUpload(info.file);
                } else if (status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },

            onDrop(e) {
                console.log('Dropped files', e.dataTransfer.files);
            },
        };

        return (
            <div style={{
                "height": "400px",
                "marginBottom": "20px"
            }}>
                <Dragger {...uploadProps}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined/>
                    </p>
                    <p className="ant-upload-text">Cliquez ou déplacez <br/>le fichier .csv dans cette zone</p>
                </Dragger>
            </div>
        )
    }

    let columns = [];
    let dataSource = [];

    if (selectedFileToUpload !== null) {
        columns = selectedFileToUpload.response.preview.headers.map((item) => {
            return {
                title: item,
                dataIndex: item,
                key: item,
                ellipsis: true,
            };
        });

        dataSource = selectedFileToUpload.response.preview.rows.map((item) => {
            return item;
        });
    }

    let stepContent = (<>{dragger()}</>)

    if (step == 1) {
        stepContent = (
            <>
                <div style={{
                    height: "500px"
                }}>
                    <Table size={"small"} dataSource={dataSource} pagination={{pageSize: 10}} columns={columns}
                           scroll={{
                               x: true
                           }}/>
                </div>

                <Row>
                    <Col span={24}><Button onClick={onClickPrevStep1Handler}>Retour</Button> <Button onClick={onClickNextStep1Handler}>Suivant</Button></Col>
                </Row>
            </>
        )
    } else if (step == 2) {
        let headerOptions = selectedFileToUpload.response.preview.headers.map((item) => {
            return (<Option value={item}>{item}</Option>)
        })

        formMapping.setFieldsValue({
            'email': (selectedFileToUpload.response.preview.headers.indexOf('email') > -1) ? 'email' : null,
            'firstname': (selectedFileToUpload.response.preview.headers.indexOf('firstname') > -1) ? 'firstname' : null,
            'lastname': (selectedFileToUpload.response.preview.headers.indexOf('lastname') > -1) ? 'lastname' : null,
            'phone': (selectedFileToUpload.response.preview.headers.indexOf('phone') > -1) ? 'phone' : null,
            'mobile_phone': (selectedFileToUpload.response.preview.headers.indexOf('mobile_phone') > -1) ? 'mobile_phone' : null,
        })

        stepContent = (
            <>
                <Form
                    form={formMapping}
                    layout="vertical"

                >
                    <Form.Item label="Champ email" name={'email'}>
                        <Select>
                            {headerOptions}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Champ prénom" name={'firstname'}>
                        <Select>
                            {headerOptions}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Champ nom" name={'lastname'}>
                        <Select>
                            {headerOptions}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Champ téléphone fixe" name={'phone'}>
                        <Select>
                            {headerOptions}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Champ téléphone mobile" name={'mobile_phone'}>
                        <Select>
                            {headerOptions}
                        </Select>
                    </Form.Item>
                </Form>

                <Row>
                    <Col span={24}><Button onClick={onClickPrevStep2Handler}>Retour</Button> <Button onClick={onImport} loading={importUploading}>Lancer l'import</Button></Col>
                </Row>
            </>
        )
    } else if (step == 3) {
        stepContent = (<>
            <Result
                status="success"
                title="Terminé ! le fichier est en cours de traitement"
                subTitle="Vous pouvez quitter cette page. La tâche d'import est accessible dans la liste des tâches automatiques en cours."
                extra={[
                    <Button type="primary" key="console" onClick={props.onClose}>
                        Fermer
                    </Button>
                ]}
            />
        </>)
    }

    return (
        <Modal open={props.open} onClose={props.onClose} showCloseIcon={true} center
               classNames={{
                   overlay: 'customOverlay',
                   modal: 'customModal',
               }}>
            <div style={{"height": "100%"}}>
                <div style={{"height": "100%"}}>
                    <Steps current={step}>
                        <Step title="Sélection du fichier" description="Fichier *.csv à importer"/>
                        <Step title="Aperçu" description="Contrôle du fichier"/>
                        <Step title="Mappage"/>
                        <Step title="En cours"/>
                    </Steps>
                    <div className="steps-content" style={{
                        margin: "25px 0 0 0",
                    }}>
                        {stepContent}
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ImportContactModal;

