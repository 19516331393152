import {jsonFetch} from "./JsonFetch";

let Bulk = {

    /**
     *
     */
    getAll: async () => {
        let url = '/bulk';

        const response = await jsonFetch('GET', url);
        if (response.ok) {
            return response.json();
        }
        throw Error(response.statusText);
    },
}

export {Bulk};
