import {Button, Drawer, Form, Input, notification, Space, Table} from "antd";
import React, {useEffect, useState} from "react"

import {DeleteOutlined, EditOutlined, PlusCircleOutlined} from '@ant-design/icons';
import {DateTime} from "luxon";
import {DocumentCategory} from "../api/DocumentCategory";
import {PageHeader} from "@ant-design/pro-components";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
function DocumentCategoriesSettingsPage() {

    const [visibleDocumentCategoryDrawer, setVisibleDocumentCategoryDrawer] = useState(false);
    const [documentCategoryIdInEdit, setDocumentCategoryIdInEdit] = useState(null);
    const [documentCategories, setDocumentCategories] = useState(false);

    const columns = [
        {
            title: 'Nom',
            dataIndex: 'name'
        },
        {
            title: 'Date de création',
            width: '180px',
            render: (row) => (<>
                {DateTime.fromISO(row.created_at).toLocaleString(DateTime.DATETIME_SHORT)}
            </>)
        },
        {
            width: '150px',
            render: (row) => (<Space>
                    <Button shape="circle" icon={<EditOutlined/>} onClick={() => {
                        onEditDocumentCategoryHandler(row.id)
                    }}/>
                    <Button type="danger" shape="circle" icon={<DeleteOutlined/>} onClick={() => {
                        onDeleteDocumentCategoryHandler(row.id)
                    }}/></Space>
            ),
        },
    ];

    useEffect(() => {
        loadDocumentCategories();
    }, []);

    const loadDocumentCategories = () => {
        DocumentCategory.getAll({}).then((json) => {
            setDocumentCategories(json);
        });
    }

    const onAddDocumentCategoryHandler = () => {
        setVisibleDocumentCategoryDrawer(true);
    }

    const onEditDocumentCategoryHandler = (documentId) => {
        setDocumentCategoryIdInEdit(documentId);
        setVisibleDocumentCategoryDrawer(true);
    }

    const onDeleteDocumentCategoryHandler = (documentCategoryId) => {
        DocumentCategory.delete(documentCategoryId).then(() => {
            loadDocumentCategories();
        });
    }

    const onSaveDocumentCategoryDrawerHandler = () => {
        closeDocumentCategoryDrawer();
        loadDocumentCategories();
    }

    const onCloseDocumentCategoryDrawerHandler = () => {
        closeDocumentCategoryDrawer();
    }

    const closeDocumentCategoryDrawer = () => {
        setDocumentCategoryIdInEdit(null);
        setVisibleDocumentCategoryDrawer(false);
    }

    return (
        <>
            <PageHeader
                className={'site-layout-sub-header-background'}
                ghost={false}
                onBack={() => window.history.back()}
                title="Catégories de document"
                subTitle="Retrouvez ici les catégories que vous pouvez attribuer à vos documents"
                extra={[
                    <Button shape="circle" icon={<PlusCircleOutlined/>} type="primary" onClick={onAddDocumentCategoryHandler}></Button>
                ]}
            >
                <Table
                    columns={columns}
                    dataSource={documentCategories}
                />

                <DocumentCategoryDrawer visible={visibleDocumentCategoryDrawer} documentCategoryId={documentCategoryIdInEdit}
                           onSave={onSaveDocumentCategoryDrawerHandler} onClose={onCloseDocumentCategoryDrawerHandler}/>
            </PageHeader>
        </>
    )
}

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function DocumentCategoryDrawer(props) {

    const [form] = Form.useForm();
    const [documentCategoryInEdit, setDocumentCategoryInEdit] = useState(null);

    useEffect(() => {
        form.resetFields();
        if (props.documentCategoryId) {
            DocumentCategory.getOne(props.documentCategoryId).then(documentCategory => {
                setDocumentCategoryInEdit(documentCategory);
                form.setFieldsValue({
                    name: documentCategory.name
                })
            })
        }
    }, [props.documentCategoryId]);

    const onSaveDrawerHandler = () => {
        let payload = form.getFieldsValue();

        if (props.documentCategoryId === null) {
            DocumentCategory.create(payload).then(() => {
                props.onSave();
                notification.success({
                    message: 'La catégorie a bien été créée.'
                })
            });
        } else {
            DocumentCategory.update(props.documentCategoryId, payload).then(() => {
                props.onSave();
                notification.success({
                    message: 'La catégorie a bien été mise à jour.'
                })
            });
        }
    }

    const title = () => {
        if (props.documentCategoryId === null) {
            return "Ajout d'une nouvelle catégorie";
        } else {
            return "Édition d'une catégorie";
        }
    }

    return (
        <>
            <Drawer title={title()} placement="left" onClose={props.onClose}
                    visible={props.visible} width={700} extra={
                <Space>
                    <Button onClick={props.onClose}>Annuler</Button>
                    <Button onClick={onSaveDrawerHandler} type="primary">
                        Enregistrer
                    </Button>
                </Space>
            }>
                <Form
                    form={form}
                    layout="vertical"
                >
                    <Form.Item name="name" label="Nom" required tooltip="This is a required field">
                        <Input placeholder="ex : marketing"/>
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    )
}

export default DocumentCategoriesSettingsPage;

