import {jsonFetch} from "./JsonFetch";

let Profile = {

    /**
     *
     */
    me: async () => {
        let url = '/profile';

        const response = await jsonFetch('GET', url);
        if (response.ok) {
            return response.json();
        }
        throw Error(response.statusText);
    },

    /**
     *
     */
    create: async (payload) => {
        let url = '/profile';

        const response = await jsonFetch('POST', url, payload);
        if (response.ok) {
            return response.json();
        }
        throw Error(response.statusText);
    },

}

export {Profile};
