import {Button, Drawer, Form, Input, notification, Space, Table} from "antd";
import React, {useEffect, useState} from "react"

import {DeleteOutlined, EditOutlined, PlusCircleOutlined} from '@ant-design/icons';
import {Tag} from "../api/Tag";
import {DateTime} from "luxon";
import {PageHeader} from "@ant-design/pro-components";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
function TagsSettings() {

    const [visibleTagsDrawer, setVisibleTagsDrawer] = useState(false);
    const [tagIdInEdit, setTagIdInEdit] = useState(null);
    const [tags, setTags] = useState(false);

    const columns = [
        {
            title: 'Nom',
            dataIndex: 'name'
        },
        {
            title: 'Date de création',
            width: '180px',
            render: (row) => (<>
                {DateTime.fromISO(row.created_at).toLocaleString(DateTime.DATETIME_SHORT)}
            </>)
        },
        {
            width: '110px',
            render: (row) => (<Space>
                    <Button shape="circle" icon={<EditOutlined/>} onClick={() => {
                        onEditTagHandler(row.id)
                    }}/>
                    <Button type="danger" shape="circle" icon={<DeleteOutlined/>} onClick={() => {
                        onDeleteTagHandler(row.id)
                    }}/></Space>
            ),
        },
    ];

    useEffect(() => {
        loadTags();
    }, []);

    const loadTags = () => {
        Tag.getAll({}).then((json) => {
            setTags(json);
        });
    }

    const onAddTagHandler = () => {
        setVisibleTagsDrawer(true);
    }

    const onEditTagHandler = (documentId) => {
        setTagIdInEdit(documentId);
        setVisibleTagsDrawer(true);
    }

    const onDeleteTagHandler = (tagId) => {
        Tag.delete(tagId).then(() => {
            loadTags();
        });
    }

    const onSaveTagDrawerHandler = () => {
        closeTagDrawer();
        loadTags();
    }

    const onCloseTagDrawerHandler = () => {
        closeTagDrawer();
    }

    const closeTagDrawer = () => {
        setTagIdInEdit(null);
        setVisibleTagsDrawer(false);
    }

    return (
        <>
            <PageHeader
                className={'site-layout-sub-header-background'}
                ghost={false}
                onBack={() => window.history.back()}
                title="Tags"
                subTitle="Retrouvez ici les tags que vous pouvez attribuer à vos contacts"
                extra={[
                    <Button shape="circle" icon={<PlusCircleOutlined/>} type="primary" onClick={onAddTagHandler}></Button>
                ]}
            >
                <Table
                    columns={columns}
                    dataSource={tags}
                />

                <TagDrawer visible={visibleTagsDrawer} tagId={tagIdInEdit}
                           onSave={onSaveTagDrawerHandler} onClose={onCloseTagDrawerHandler}/>
            </PageHeader>
        </>
    )
}

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function TagDrawer(props) {

    const [form] = Form.useForm();
    const [tagInEdit, setTagInEdit] = useState(null);

    useEffect(() => {
        if (props.tagId) {
            Tag.getOne(props.tagId).then(tag => {
                setTagInEdit(tag);
                form.setFieldsValue({
                    name: tag.name
                })
            })
        }
    }, [props.tagId]);

    useEffect(() => {
        form.resetFields();
    }, [props.visible]);

    const onSaveDrawerHandler = () => {
        let payload = form.getFieldsValue();

        if (props.tagId === null) {
            Tag.create(payload).then(() => {
                props.onSave();
                notification.success({
                    message: 'Le tag a bien été créé.'
                })
            });
        } else {
            Tag.update(props.tagId, payload).then(() => {
                props.onSave();
                notification.success({
                    message: 'Le tag a bien été mis à jour.'
                })
            });
        }
    }

    const title = () => {
        if (props.tagId === null) {
            return "Ajout d'un nouveau tag";
        } else {
            return "Édition d'un tag";
        }
    }

    return (
        <>
            <Drawer title={title()} placement="left" onClose={props.onClose}
                    visible={props.visible} width={700} extra={
                <Space>
                    <Button onClick={props.onClose}>Annuler</Button>
                    <Button onClick={onSaveDrawerHandler} type="primary">
                        Enregistrer
                    </Button>
                </Space>
            }>
                <Form
                    form={form}
                    layout="vertical"
                >
                    <Form.Item name="name" label="Nom" required tooltip="This is a required field">
                        <Input placeholder="ex : marketing"/>
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    )
}

export default TagsSettings;

