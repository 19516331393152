import React, {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";

import {Button, Form, Input, Layout} from "antd";

import LayoutPage from "./LayoutEmailValidationPages";
import ory from "../../helpers/ory";

const { Content } = Layout;


const LoginRecovery = () => {
    const [loading, setLoading] = useState(false);
    const [formLogin] = Form.useForm();

    const [validationFailed, setValidationFailed] = useState(null)
    const [emailSent, setEmailSent] = useState(false)

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    // Second, gather session data, if the user is not logged in, redirect to login
    useEffect(() => {
        let flow = searchParams.get('flow');
        if (flow) {
            ory
                .getSelfServiceRecoveryFlow(searchParams.get('flow'))
                .then(({data}) => {
                    console.log('data', data);
                })
        }
    }, [])

    const onSubmit = (values) => {
        let flow = searchParams.get('flow');
        ory.submitSelfServiceRecoveryFlow(flow, {
            password: values['password'],
            method: 'password',
            // csrf_token: data.ui.nodes[0].attributes.value
        }).then(() => {

        })
    }

    const onFinish = () => {
        let values = formLogin.getFieldsValue();
        onSubmit(values)
    }

    return (
        <LayoutPage title="Demande de changement de mot de passe">
            <Content>
                <div className="login-form-box">
                    <Form
                        form={formLogin}
                        onFinish={onFinish}
                    >

                        <Form.Item
                            className="mb-2"
                            name="password"
                            rules={[
                                {
                                    required: true
                                },
                            ]}
                        >
                            <Input
                                type="password"
                                placeholder="Mot de passe"
                            />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button" loading={loading}>
                                Envoyer
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Content>
        </LayoutPage>
    )
}

export default LoginRecovery;
