import {jsonFetch} from "./JsonFetch";

let ContactTimeline = {
    /**
     *
     */
    getOne: async (id) => {
        let url = '/contact/' + id + '/timeline';

        const response = await jsonFetch('GET', url);
        if (response.ok) {
            return response.json();
        }
        throw Error(response.statusText);
    },
}

export {ContactTimeline};