import {jsonFetch} from "./JsonFetch";

let Quota = {

    /**
     *
     */
    getAvailableQuota: async () => {
        let url = '/quota/available';

        const response = await jsonFetch('GET', url);
        if (response.ok) {
            return response.json();
        }
        throw Error(response.statusText);
    },
}

export {Quota};
