import React, {useEffect, useState} from "react";
import {Link, useNavigate, useSearchParams} from "react-router-dom";

import {Alert, Button, Col, Form, Input, Layout, notification, Result, Row} from "antd";

import LayoutPage from "./LayoutEmailValidationPages";
import ory from "../../helpers/ory";

const { Content } = Layout;

const EmailVerification = () => {
    const [loading, setLoading] = useState(false);
    const [formLogin] = Form.useForm();

    const [validationFailed, setValidationFailed] = useState(null)
    const [emailSent, setEmailSent] = useState(false)

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    // Returns either the email or the username depending on the user's Identity Schema
    const getUserName = (identity) =>
        identity.traits.email || identity.traits.username

    // Second, gather session data, if the user is not logged in, redirect to login
    useEffect(() => {
        let flow = searchParams.get('flow');
        if (flow) {
            ory
                .getSelfServiceVerificationFlow(searchParams.get('flow'))
                .then(({data}) => {
                    if (data.state == "passed_challenge") {
                        setValidationFailed(false);
                    } else {
                        setValidationFailed(true);
                    }
                })
        }
    }, [])

    const onSubmit = (values) => {
        setLoading(true);
        ory.initializeSelfServiceVerificationFlowForBrowsers().then((data) => {
            ory.submitSelfServiceVerificationFlow(data.data.id, {
                email: values['email'],
                method: 'link',
                csrf_token: data.data.ui.nodes[0].attributes.value
            }).then(() => {
                setEmailSent(true);
            })
        }).catch(() => {
            notification['error']({
                message: "L'envoi de l'email à échoué"
            });
        }).finally(() => {
            setLoading(false);
        });
    }

    const onFinish = () => {
        let values = formLogin.getFieldsValue();
        onSubmit(values)
    }

    let pageResult = null;
    let message = null;

    if (validationFailed === true) {
        message = (
            <Row style={{marginBottom: "25px"}}>
                <Col span={24}>
                    <Alert
                        message="Impossible de valider votre adresse email"
                        description="Lien erroné ou expiré. Veuillez recommencer."
                        type="error"
                        showIcon
                    />
                </Col>
            </Row>
        )
    } else if (emailSent === true) {
        message = (
            <Row style={{marginBottom: "25px"}}>
                <Col span={24}>
                    <Alert
                        message="L'email de validation a été envoyé"
                        description="Après avoir cliqué sur le bouton présent dans l'email, vous pourrez vous connecter."
                        type="success"
                        showIcon
                    />
                </Col>
            </Row>
        )
    }

    if (emailSent === true) {
        pageResult = (
            <div className="login-form-box">
                {message}
            </div>
        )
    } else if (validationFailed == null || validationFailed == true) {
        pageResult = (
            <div className="login-form-box">
                {message}
                <Form
                    form={formLogin}
                    onFinish={onFinish}
                >

                    <Form.Item
                        className="mb-2"
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: "L'email ne peut pas être vide",
                            },
                        ]}
                    >
                        <Input
                            type="email"
                            placeholder="Email"
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button" loading={loading}>
                            Envoyer
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        )
    } else if (validationFailed === false) {
        pageResult = (
            <Result
                status="success"
                title="Votre adresse email a été validée avec succès"
                subTitle="Vous pouvez dès maintenant vous connecter"
                extra={[
                    <Link to={"/login"} key={'login'}>
                        <Button type="primary" key="console">
                            Connexion
                        </Button>,
                    </Link>
                ]}
            />
        )
    }

    return (
        <LayoutPage title="Validation de l'email">
            <Content>
                {pageResult}
            </Content>
        </LayoutPage>
    )
}

export default EmailVerification;
