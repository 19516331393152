import React, {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";

import {Oauth2} from "../../api/Oauth2";
import {Layout, notification} from "antd";

import LayoutPage from "../login/LayoutIdentificationPages";
import ory from "../../helpers/ory";

const {Content} = Layout;

const OAuthConsent = () => {

    const [searchParams, setSearchParams] = useSearchParams();

    let challenge = searchParams.get("consent_challenge")

    // Second, gather session data, if the user is not logged in, redirect to login
    useEffect(() => {

        Oauth2.consentChallenge(challenge).then((data) => {
            if (data.redirect_to) {
                window.location = data.redirect_to;
            }
        })

    }, [])

    return (
        <LayoutPage>
            <Content>
                Chargement ...
            </Content>
        </LayoutPage>
    )
}

export default OAuthConsent;
